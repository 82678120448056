<template>
  <v-parallax dark src="/background.jpg"
              height="430"
              scale="0.5">
    <v-row align="center" justify="center">
      <v-col cols="10">
        <v-row align="center" justify="center">
          <v-col cols="12" md="6" xl="8">
            <h1 class="display-2 font-weight-bold mb-4 mt-8 ">
              White label travel itinerary planner
            </h1>
            <h2 class="font-weight-light  ">
              Create your own fully customized travel platform<br />
              with your own branding and content.<br />
              All in one place.
            </h2>
            <v-btn
              rounded
              outlined
              large
              dark
              class="mt-5"
              href="https://jordan.yourtravelitinerary.com"
              target="_blank"
              color="primary"
            >
              Open Example
              <v-icon class="ml-2">mdi-rocket-launch</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"></v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="svg-border-waves text-white">
      <v-img src=""/>
    </div>
  </v-parallax>
  <v-container fluid id="features" class="mt-2">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10">
        <v-row align="center" justify="space-around">
          <v-col
            cols="12"
            sm="4"
            class="text-center"
            v-for="(feature, i) in features"
            :key="i"
          >
            <v-hover>
              <template v-slot:default="{  isHovering, props }">
                <v-card
                  v-bind="props"
                  class="card"
                  shaped
                  :elevation="isHovering ? 10 : 4"
                  :class="{ up: isHovering }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-effect': isHovering }"
                  ></v-img>
                  <h2 class="font-weight-regular">{{ feature.title }}</h2>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  <section id="about">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="12">
              <h1 class="font-weight-light display-2 mb-2">
                All in one
              </h1>
              <v-row>
                <v-col cols="12" class="d-flex align-center">
<!--                  <v-img-->
<!--                    src=""-->
<!--                    max-width="60px"-->
<!--                    class="mr-4"-->
<!--                  />-->
                  <v-icon class="mr-4">
                    mdi-map-marker
                  </v-icon>
                  <p class="text-justify">
                    Destinations: Insert destinations to visit with description, images, ratings and attributes, so that the user can find easily the best places for his trip with the help of your experience.
                  </p>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
<!--                  <v-img-->
<!--                    src=""-->
<!--                    max-width="60px"-->
<!--                    class="mr-4"-->
<!--                  />-->
                  <v-icon class="mr-4">
                    mdi-transfer
                  </v-icon>
                  <p class="text-justify">
                    Transfers: Insert exact or broad transport data for bus, trains etc. The users gets intelligent hints what to use and how to combine transfer options.
                  </p>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
<!--                  <v-img-->
<!--                    src=""-->
<!--                    max-width="60px"-->
<!--                    class="mr-4"-->
<!--                  />-->
                  <v-icon class="mr-4">
                    mdi-database
                  </v-icon>
                  <p class="text-justify">
                    Fully customizable: You can customize all content and style, so that the platform is really made by you.
                  </p>
                </v-col>
              </v-row>
            </v-col>
<!--            <v-col cols="12" md="5" class="d-none d-md-flex">-->
<!--              <v-img-->
<!--                src="https://images.pexels.com/photos/1051075/pexels-photo-1051075.jpeg"-->
<!--                class="d-block ml-auto mr-0"-->
<!--                max-width="250px"-->
<!--              />-->
<!--            </v-col>-->
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <section id="examples">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10">
          <v-row >
<!--            <v-col sm="3" class="hidden-xs-only">-->
<!--              <v-img src="" class="d-block ml-auto mr-auto" max-width="350px" />-->
<!--            </v-col>-->
            <v-col cols="12" sm="12" class="white--text text-left">
              <h1 class="font-weight-light display-2 mb-2">Example: Jordan Trip Planner</h1>
              <h1 class="font-weight-light display-3 mb-2 text-subtitle-2">
                Plan your trip in Jordan with bus and taxis.
                Find the best places to visit and the best hotels to stay. Create your own itinerary and share it with your friends.
              </h1>
              <v-container class="d-flex justify-center">
                <v-btn rounded
                       outlined href="https://jordan.yourtravelitinerary.com" target="_blank" large color="primary" class="mt-4">
                  <v-icon class="mr-2">
                    mdi-rocket-launch
                  </v-icon>
                  Open Example
                </v-btn>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <section class="my-10">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10">
          <h1 class="font-weight-light display-1">Contact us</h1>
          <h3 class="font-weight-light mt-3">
            Don't hesitate to ask us any questions.
          </h3>
          <h3 class="font-weight-light mt-3">
            Email address:<b> mail /at/ yourtravelitinerary.com</b>
          </h3>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      features: [
        {
          img: '',
          title: 'Your travel platform',
          text: 'Setup information about destinations, regions, transfers, etc. to generate a full digital travel guide for your customers.',
        },
        {
          img: '',
          title: 'Unique itinerary planner',
          text: 'Let your customers plan their own individual trip with the AI-driven high user friendly itinerary planner',
        },
        {
          img: '',
          title: 'Easy to use',
          text: 'No need to be a tech expert. Just input content through an easy-to-use interface and customize the look and feel of your travel guide.',
        },
      ],
    };
  },
  components: {},
};
</script>

<style>
.card {
  min-height: 110px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}
</style>
