<template>
  <page-top-area header-text="Edit about text" info-text="Set about text">
    <template v-slot:button-area>
    </template>
  </page-top-area>
  <EditTextContent slug="ABOUT"/>
</template>

<script>
import EditTextContent from '@/components/EditTextContent.vue';

export default {
  components: { EditTextContent },

};
</script>
