<template>
  <LoaderPlaceholder
    v-if="this.loading"
  />
  <form v-else>
    <page-top-area :header-text='this.isNew() ? "New region" : "Edit " + this.name'
                   :info-text="'Manage a region'">
      <template v-slot:button-area>
        <v-row>
          <v-col cols="6">
          </v-col>
          <v-col cols="6">
            <v-btn
              prepend-icon="mdi-delete"
              variant="text"
              color="#4485ca"
              @click="handleClickDelete"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </page-top-area>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          :counter="10"
          label="Name"
          required
          @input="v$.name.$touch()"
          @blur="v$.name.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="allowFiltering"
          :error-messages="allowFilteringErrors"
          label="Allow use as planning filter for user?"
          @input="v$.allowFiltering.$touch()"
          @blur="v$.allowFiltering.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <QuillTextarea v-if="!this.loading" v-model:content="description"
                       placeholder="Detailed description"/>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col
        cols="6"
      >
      </v-col>
      <v-col
        cols="6"
      >
        <v-btn
          @click="handleSave"
          :disabled="(this.isNew() && !v$.$dirty)  || v$.$error"
          color="primary"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </form>
  <ConfirmationDialog
    :open="deleteModalOpen"
    :onAbort="handleAbortDeleteModal"
    :onConfirm="handleConfirmDeleteModal"
    :text="'Do you really want to delete?'"
  />
  <RequestErrorNotification :error="submitError"/>
</template>

<script>
import {
  maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getErrorMessages } from '@/utils/validationUtils';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import QuillTextarea from '@/components/QuillTextarea.vue';
import PageTopArea from '@/components/PageTopArea.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';

export default {
  components: {
    QuillTextarea,
    PageTopArea,
    ConfirmationDialog,
  },
  validations: {
    name: {
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    // description: { maxLength: maxLength(2048) }, // Todo: error else. Dirty not working here, :(
    allowFiltering: { },
  },
  mounted() {
    this.loadBasicData();
    this.touchIgnoreableElements();
  },
  data() {
    return ({
      loading: !this.isNew(),
      submitError: null,
      uuid: this.isNew() ? null : this.$route.params.uuid,
      v$: useVuelidate(),
      deleteModalOpen: false,
      name: '',
      description: '',
      allowFiltering: false,
    });
  },
  computed: {
    nameErrors() {
      return getErrorMessages(this.v$.name, 'Name');
    },
    descriptionErrors() {
      return getErrorMessages(this.v$.description, 'Description');
    },
    allowFilteringErrors() {
      return getErrorMessages(this.v$.allowFiltering, 'Show as filter');
    },
  },
  methods: {
    isNew() {
      return this.$route.params.uuid === 'new';
    },
    loadBasicData() {
      if (!this.uuid) {
        return;
      }
      axios.get(`${getBackendAdminHost(this.$store)}/admin/region/${this.uuid}`, { withCredentials: true })
        .then((response) => {
          this.applyResponseData(response.data);
          this.loading = false;
        })
        .catch(() => {
          this.submitError = 'Error during request';
        });
    },
    applyResponseData(data) {
      this.name = data.name;
      this.description = data.description;
      this.allowFiltering = data.allowFiltering;
    },
    handleClickDelete() {
      this.deleteModalOpen = true;
    },
    handleAbortDeleteModal() {
      this.deleteModalOpen = false;
    },
    buildRequestObject() {
      return {
        name: this.name,
        description: this.description,
        allowFiltering: this.allowFiltering,
      };
    },
    create(requestBody) {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/region`, requestBody, { withCredentials: true })
        .then((response) => {
          this.loading = false;
          this.uuid = response.data.uuid;
          this.$router.push(`/region/${response.data.uuid}`);
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update(requestBody) {
      axios.put(`${getBackendAdminHost(this.$store)}/admin/region/${this.uuid}`, requestBody, { withCredentials: true })
        .then(() => {
          this.$router.push('/regions');
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleConfirmDeleteModal() {
      axios.delete(`${getBackendAdminHost(this.$store)}/admin/region/${this.uuid}`, { withCredentials: true })
        .then(() => {
          this.$router.push('/regions');
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleSave() {
      this.loading = true;

      const requestBody = this.buildRequestObject();

      if (this.isNew()) {
        this.create(requestBody);
      } else {
        this.update(requestBody);
      }
    },
    touchIgnoreableElements() {
      this.v$.allowFiltering.$touch();
    },
  },

};
</script>
