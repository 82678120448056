<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="auto"
      persistent
    >
      <v-card>
        <v-card-text>
          <div class="text-subtitle-2 my-2">
            Cookies Usage
          </div>
          <div class="text-body-2">
            This website uses cookies to ensure you get the best experience on our website.
            <br/>
            Cookies will be used to store your preferences and login state.
            <br/>
            <br/>
            Besides it uses Google Recaptcha to prevent spam. See <a
            href="https://policies.google.com/privacy">Google Privacy Policy</a>.
          </div>
        </v-card-text>
          <v-btn color="primary" class="text-h6 ma-3"  @click="handleAccept">
            Accept Cookies
          </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: 'CookieConsentBanner',
  props: {
    value: Boolean,
  },
  data() {
    return ({
      dialog: Cookies.get('cookiesConsent') !== '1',
    });
  },
  created() {
  },
  methods: {
    handleAccept() {
      window.location.reload();
      Cookies.set('cookiesConsent', '1', { expires: 30 * 6 });
    },
  },
};
</script>
