<template>
  <v-autocomplete
    item-title="name"
    :disabled="this.loading"
    v-model="autocompleteModel"
    :items="agencyList"
    :error-messages="agencyErrors"
    label="Agencies"
    @blur="v$.agencies?.$touch()"
    :auto-select-first="true"
    :return-object="true"
    :multiple="true"
    :chips="true"
  />
</template>

<script>

import axios from 'axios';
import { startCase } from 'lodash';
import { getBackendAdminHost, getBackendHost } from '@/utils/urlUtils';

export default {
  name: 'AgencySelection',
  props: {
    selectedValues: Array,
    agencyErrors: Array,
    multiple: {
      type: Boolean,
      default: false,
    },
    v$: Object,
  },
  mounted() {
    axios.get(`${getBackendAdminHost(this.$store)}/admin/agency`, { withCredentials: true })
      .then((response) => {
        this.agencyList = response.data.map((agency) => ({ ...agency, name: startCase(agency.name) }));
        this.loading = false;
      });
  },
  data() {
    return {
      loading: true,
      agencyList: [],
    };
  },
  computed: {
    autocompleteModel: {
      get() {
        return !this.loading && this.selectedValues.map((selectedValue) => this.agencyList.find((agency) => agency.uuid === selectedValue)).filter((selectedValue) => selectedValue);
      },
      set(value) {
        if (this.v$.agencies) {
          this.v$.agencies.$touch();
        }
        this.$emit('update:selectedValues', value.map((v) => this.agencyList.find((agency) => (v.name && v.name.toLowerCase() === agency.name.toLowerCase())
          || (typeof v === 'string' && agency.name.toLowerCase() === v.toLowerCase())).uuid));
      },
    },
  },
};
</script>
