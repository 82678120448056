<template>
  <v-autocomplete
    item-title="name"
    :disabled="this.loading"
    v-model="autocompleteModel"
    :items="routeList"
    :error-messages="routeErrors"
    label="Route"
    :auto-select-first="true"
    :return-object="true"
  ></v-autocomplete>
</template>

<script>

import axios from 'axios';
import { getBackendAdminHost, getBackendHost } from '@/utils/urlUtils';

export default {
  name: 'RouteSelection',
  props: {
    selectedValue: Number,
    routeErrors: Array,
    v$: Object,
  },
  mounted() {
    axios.get(`${getBackendAdminHost(this.$store)}/admin/route/basic`, { withCredentials: true })
      .then((response) => {
        this.routeList = response.data;
        this.loading = false;
        if (this.routeList.length === 1 && this.selectedValue === null) {
          // eslint-disable-next-line prefer-destructuring
          this.autocompleteModel = this.routeList[0];
        }
      });
  },
  data() {
    return {
      loading: true,
      routeList: [],
    };
  },
  computed: {
    autocompleteModel: {
      get() {
        return !this.loading && this.selectedValue && this.routeList.find((c) => c.uuid === this.selectedValue).name;
      },
      set(value) {
        if (this.v$?.routeUuid) {
          this.v$.routeUuid.$touch();
        }
        this.$emit('update:selectedValue', this.routeList.find((c) => c.name === value?.name).uuid);
      },
    },
  },
};
</script>
