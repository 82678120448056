import PolylineUtil from 'polyline-encoded';

export const getPathFromRoute = (route) => {
  const { path } = route;
  if (path) {
    return path;
  }
  return PolylineUtil.encode([[route.startRoutePlace.lat, route.startRoutePlace.lon], [route.endRoutePlace.lat, route.endRoutePlace.lon]]);
};

export const decodePathFormat = (path) => {
  const waypoints = path.split(';');
  return waypoints.map((waypoint) => {
    const [lat, lon] = waypoint.split(',');
    return { lat: parseFloat(lat), lon: parseFloat(lon) };
  });
};
