import axios from 'axios';
import router from './router';

axios.interceptors.response.use((response) => {
  if (response.status === 200 || response.status === 201) {
    return Promise.resolve(response);
  }
  return Promise.reject(response);
}, (error) => {
  switch (error.response.status) {
    case 403:
      router.replace({
        path: '/login',
        query: { redirect: router.currentRoute.value.fullPath },
      });
      return null;
    default:
      return Promise.reject(error.response);
  }
});

export default axios;
