<template>
  <div>
    <page-top-area header-text="Routes" info-text="Manage all routes">
      <template v-slot:button-area>
        <v-btn
          prepend-icon="mdi-plus-circle"
          @click="handleCreate"
          variant="text"
          color="#4485ca"
        >
          New route
        </v-btn>
      </template>
    </page-top-area>
    <LoaderPlaceholder
      v-if="this.loading"
    />
    <div v-else-if="this.entries.length===0">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              No routes found.
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <vue-good-table
        :columns="this.columns"
        :rows="this.entries"
        :pagination-options="{
        enabled: true,
        perPage: 30,
        perPageDropdown: [15, 30, 50, 100],
        dropdownAllowAll: false,
      }"
        styleClass="vgt-table"
      >
        <template #table-row="props">
        <span v-if="props.column.field === 'name'">
          <router-link :to="'/route/'+props.row.uuid">
            <div class="text-subtitle-1 text-decoration-underline">
              {{ props.row.name }}
            </div>
          </router-link>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
        </template>
      </vue-good-table>
    </div>
    <RequestErrorNotification :error="submitError"/>
  </div>
</template>

<script>
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import { mapTransferTypeId } from '@/config/transferTypes';

export default {
  components: {},
  data() {
    return {
      loading: true,
      submitError: null,
      entries: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Start',
          field: 'startGeoBaseName',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'End',
          field: 'endGeoBaseName',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Type',
          field: 'transferTypeName',
          filterOptions: {
            enabled: true,
          },
        },

      ],
    };
  },
  methods: {
    handleCreate() {
      this.$router.push('/route/new');
    },
  },
  mounted() {
    this.axios.get(`${getBackendAdminHost(this.$store)}/admin/route`, { withCredentials: true })
      .then((response) => {
        this.entries = response.data.map((entry) => ({
          ...entry, startGeoBaseName: (entry.startGeoBase || entry.startRoutePlace)?.name, endGeoBaseName: (entry.endGeoBase || entry.endRoutePlace)?.name, transferTypeName: mapTransferTypeId(entry.transferTypeId).name,
        }));
        this.loading = false;
      })
      .catch(() => {
        this.submitError = 'Error during request';
      });
  },
};
</script>
