<template>
  <div id="container">
    <div id="mapContainer"></div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet/dist/leaflet.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import L from 'leaflet-gpx';
import PolylineUtil from 'polyline-encoded';
import { tr } from 'vuetify/locale';
import { decodePathFormat } from '@/utils/geoUtils';

export default {
  name: 'MapView',
  props: {
    paths: Array,
  },
  data() {
    return {
      drawnPaths: [],
      mapDiv: null,
    };
  },
  methods: {
    setupLeafletMap() {
      this.mapDiv = L.map('mapContainer')
        .setView([17.385044, 78.486671], 13);

      L.tileLayer(
        'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          maxZoom: 18,
          id: 'mapbox/streets-v11',
        },
      )
        .addTo(this.mapDiv);

      this.updateDrawnPaths();
    },
    drawPath(path) {
      // if (path.includes('<wpt')) {
      //   this.drawnPaths.push(new L.GPX(path, {
      //     async: true,
      //     marker_options: {
      //       startIconUrl: 'https://raw.githubusercontent.com/mpetazzoni/leaflet-gpx/main/pin-icon-wpt.png',
      //       endIconUrl: 'https://raw.githubusercontent.com/mpetazzoni/leaflet-gpx/main/pin-icon-wpt.png',
      //       shadowUrl: 'https://raw.githubusercontent.com/mpetazzoni/leaflet-gpx/main/pin-icon-wpt.png',
      //       iconSize: [0, 0],
      //       shadowSize: [0, 0],
      //     },
      //   }).on('loaded', (e) => {
      //     this.mapDiv.fitBounds(e.target.getBounds());
      //   }).addTo(this.mapDiv));
      // } else {
      //   const pathLayer = L.polyline(PolylineUtil.decode(path, 5), { color: '#4485ca' })
      //     .addTo(this.mapDiv);
      //   this.drawnPaths.push(pathLayer);
      const pathLayer = L.polyline(decodePathFormat(path), { color: '#4485ca' })
        .addTo(this.mapDiv);
      this.drawnPaths.push(pathLayer);
      // }
    },
    centerDrawnPaths() {
      if (this.drawnPaths.length === 0 || this.drawnPaths.filter((path) => path.getBounds().isValid()).length === 0) {
        return;
      }
      const bounds = this.drawnPaths.reduce((acc, path) => acc.extend(path.getBounds()), new L.LatLngBounds());
      if (bounds) {
        this.mapDiv.fitBounds(bounds);
      }
    },
    updateDrawnPaths() {
      this.drawnPaths.forEach((path) => {
        this.mapDiv.removeLayer(path);
      });
      this.drawnPaths = [];
      this.paths.forEach((path) => {
        this.drawPath(path);
      });
      this.centerDrawnPaths();
    },
  },
  mounted() {
    this.setupLeafletMap();
  },
  watch: {
    paths() {
      this.updateDrawnPaths();
    },
  },
};
</script>

<style scoped>
#mapContainer {
  width: 80vw;
  height: 50vh;
}
</style>
