<template>
  <LoaderPlaceholder
    v-if="this.loading"
  />
  <div v-else>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            Get entries for the following country:
          </v-col>
          <v-col>
            <CountrySelection
              v-model:selectedValue="this.countryId"
              :v$="v$"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              :loading="loading"
              @click="handleWizard"
            >
              Wizard data
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="importText"
              :error-messages="importTextErrors"
              :counter="2500"
              label="Name, lat, lon, description"
              required
              @input="v$.importText.$touch()"
              @blur="v$.importText.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              class="float-right"
              :loading="loading"
              @click="handleImport"
            >
              Import
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
  <RequestErrorNotification :error="submitError" />
</template>

<script>
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import CountrySelection from '@/components/CountrySelection.vue';
import useVuelidate from '@vuelidate/core';
import { maxLength } from '@vuelidate/validators';
import { getErrorMessages } from '@/utils/validationUtils';
import { mapGeoBaseName } from '@/config/geoBaseTypes';

export default {
  components: { CountrySelection },
  props: {
  },
  data() {
    return {
      loading: true,
      submitError: null,
      countryId: null,
      importText: '',
      v$: useVuelidate(),
      entries: [
      ],
      columns: {
        type: Array,
        required: false,
        default: () => [
          {
            label: 'Active',
            field: 'active',
            type: 'boolean',
          },
          {
            label: 'Name',
            field: 'name',
          },
          {
            label: 'Country',
            field: 'country',
          },
        ],
      },
      slug: this.$route.params.slug,
    };
  },
  validations: {
    countryId: {},
    importText: { maxLength: maxLength(2550) },
  },
  computed: {
    importTextErrors() {
      return getErrorMessages(this.v$.importText, 'Import Text');
    },
  },
  methods: {
    buildRequestObject() {
      return { importText: this.importText, countryId: this.countryId, region: null };
    },
    handleWizard() {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/ai-wizard/${mapGeoBaseName(this.slug).id}/new-entities`, this.buildRequestObject(), { withCredentials: true })
        .then((response) => {
          this.importText += `\n${response.data.map((d) => `${d.name}, ${d.lat}, ${d.lon}, ${d.description || ''}`).join('\n').replaceAll('\n\n', '\n')}`;
          // const changedEntry = this.entries.find((d) => d.uuid === entryUuid);
          // changedEntry.active = !changedEntry.active;
          // this.entries = [...this.entries]; // update
        }).catch(() => {
          this.submitError = 'Error switching active state';
        });
    },
    handleImport() {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/${this.slug}/import-entities`, { countryId: this.countryId, region: null, importText: this.importText }, { withCredentials: true })
        .then((response) => {
          this.submitError = `Created ${response.data.createdEntities.length} entities!`;
          window.location.href = `/${this.slug}s`;
        }).catch(() => {
          this.submitError = 'Error switching active state';
        });
    },
  },
  mounted() {
    this.axios.get(`${getBackendAdminHost(this.$store)}/admin/${this.slug}`, { withCredentials: true })
      .then((response) => {
        this.entries = response.data;
        this.loading = false;
      }).catch(() => {
        this.submitError = 'Error during request';
      });
  },
};
</script>
