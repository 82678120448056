import { createApp } from 'vue';
import VueGoodTablePlugin from 'vue-good-table-next';
import RequestErrorNotification from '@/components/RequestErrorNotification.vue';
import LoaderPlaceholder from '@/components/LoaderPlaceholder.vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { QuillEditor } from '@vueup/vue-quill';
import VueAxios from 'vue-axios';
import PageTopArea from '@/components/PageTopArea.vue';
import Cookies from 'js-cookie';
import axios from './config/axiosInstance';
import App from './App.vue';
import router from './config/router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

loadFonts();

createApp(App)
  .use(VueAxios, axios)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueGoodTablePlugin)
  .use(Cookies.get('cookiesConsent') === '1' ? VueReCaptcha : {}, { siteKey: '6LfIKPYgAAAAAMkGEpiKqsIl-8QVuit9ZIRNaxmU' })
  .component('RequestErrorNotification', RequestErrorNotification)
  .component('LoaderPlaceholder', LoaderPlaceholder)
  .component('QuillEditor', QuillEditor)
  .component('PageTopArea', PageTopArea)
  .mount('#app');
