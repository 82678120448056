<template>
  <v-row>
      <v-radio-group v-model=radioGroupDomains>
        <v-radio
          v-for="domain in this.domains"
          :key="domain.id"
          :label="domain.name"
          :value="domain.name"
        ></v-radio>
      </v-radio-group>
  </v-row>
  <form>
    <v-row>
      <v-text-field
        v-model="domainName"
        :error-messages="domainNameErrors"
        label="New Domain Name"
        required
        @input="v$.domainName.$touch()"
        @blur="v$.domainName.$touch()"
      />
    </v-row>
    <v-row justify="space-between">
      <v-col
        cols="12"
      >
        <v-btn
          class="float-right"
          :disabled="!v$.$dirty || v$.$error"
          :loading="loading"
          @click="handleNewDomain"
        >
          Create domain
        </v-btn>
      </v-col>
    </v-row>
    <RequestErrorNotification :error="submitError" />
  </form>
</template>

<script>
import {
  required, maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getErrorMessages } from '@/utils/validationUtils';
import axios from 'axios';
import { getCRMBackendHost } from '@/utils/urlUtils';

export default {
  components: {
  },
  validations: {
    domainName: {
      required,
      maxLength: maxLength(10),
      minLength: minLength(3),
    },
  },
  mounted() {
    this.loadDomains();
  },
  data() {
    return ({
      v$: useVuelidate(),
      loading: false,
      submitError: null,
      domainName: '',
      domains: [],
    });
  },
  watch() {

  },
  computed: {
    radioGroupDomains: {
      get() {
        return this.$store.state.selectedDomain;
      },
      set(value) {
        this.$store.commit('selectDomain', value);
      },
    },
    domainNameErrors() {
      return getErrorMessages(this.v$.domainName, 'Domain name');
    },
  },
  methods: {
    loadDomains() {
      axios.get(`${getCRMBackendHost()}/domain`, { withCredentials: true })
        .then((response) => {
          this.domains = response.data;
          this.loading = false;
        }).catch(() => {
          this.submitError = 'Error during request';
        });
    },
    handleNewDomain() {
      this.loading = true;
      this.submitError = null;

      const requestBody = {
        name: this.domainName,
      };

      axios.post(`${getCRMBackendHost()}/domain`, requestBody, { withCredentials: true })
        .then(() => {
          this.$store.commit('selectDomain', this.domainName);
          this.loadDomains();
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
  },
};
</script>
