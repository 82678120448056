<template>
  <div>
    <page-top-area header-text="Tags" info-text="Manage all tags">
      <template v-slot:button-area>
        <v-btn
          prepend-icon="mdi-plus-circle"
          @click="handleCreate"
          variant="text"
          color="#4485ca"
        >
          New tag
        </v-btn>
      </template>
    </page-top-area>
    <LoaderPlaceholder
      v-if="this.loading"
    />
    <div v-else-if="this.entries.length===0">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              No tags found.
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <v-row class="pb-5" >
        <v-col>
          <div class="text-body-2">
            At least 5 assignments of places are necessary to show the tag as filter option for users.
          </div>
        </v-col>
      </v-row>
      <vue-good-table
        :columns="this.columns"
        :rows="this.entries"
        :pagination-options="{
        enabled: true,
        perPage: 30,
        perPageDropdown: [15, 30, 50, 100],
        dropdownAllowAll: false,
      }"
        styleClass="vgt-table"
      >
        <template #table-row="props">
        <span v-if="props.column.field === 'name'">
          <router-link :to="'/tag/'+props.row.uuid">
            <div class="text-subtitle-1 text-decoration-underline">
              {{ props.row.name }}
            </div>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'color'">
            <v-chip :color="props.row.color">
              {{ props.formattedRow[props.column.field] }}
            </v-chip>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
        </template>
      </vue-good-table>
    </div>
    <RequestErrorNotification :error="submitError"/>
  </div>
</template>

<script>
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import { mapTagTypeId } from '@/config/tagTypes';

export default {
  components: {},
  data() {
    return {
      loading: true,
      submitError: null,
      entries: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Type',
          field: 'typeName',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Color',
          field: 'color',
        },
      ],
    };
  },
  methods: {
    handleCreate() {
      this.$router.push('/tag/new');
    },
  },
  mounted() {
    this.axios.get(`${getBackendAdminHost(this.$store)}/admin/tag`, { withCredentials: true })
      .then((response) => {
        this.entries = response.data.map((entry) => ({
          ...entry,
          typeName: mapTagTypeId(entry.typeId).name,
        }));
        this.loading = false;
      })
      .catch(() => {
        this.submitError = 'Error during request';
      });
  },
};
</script>
