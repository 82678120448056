<template>
  Loading.. until vuetify included skeleton..
</template>

<script>

export default {
  name: 'LoaderPlaceholder',
};
</script>
