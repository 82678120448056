import VuexPersistence from 'vuex-persist';
import { createStore } from 'vuex';
import { deleteCookie } from '@/utils/cookieUtils';

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
});

export default createStore({
  state: {
    loggedIn: false,
    selectedDomain: 'test',
  },
  getters: {},
  mutations: {
    login(state, domains) {
      const defaultDomain = domains[0];
      if (domains.includes(state.selectedDomain) === false) {
        state.selectedDomain = defaultDomain;
      }
      state.loggedIn = true;
    },
    logout(state) {
      deleteCookie('JSESSIONID');
      state.loggedIn = false;
    },
    selectDomain(state, domainName) {
      state.selectedDomain = domainName;
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin],
});
