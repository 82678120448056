<template>
  <v-app>
    <CookieConsentBanner />
    <v-layout>
      <Header>
        <template v-slot:menu-hamburger-button>
          <v-container class="hidden-sm-and-up justify-center">
            <v-btn v-if="this.$store.state.loggedIn" variant="text" @click="drawer = !drawer" >
              <v-icon color="white">mdi-menu</v-icon>
            </v-btn>
            <v-btn v-else variant="text" class="text-body-2" color="white" @click="this.$router.push('/')">
              <template v-slot:prepend>
                <v-icon color="white">mdi-table </v-icon>
              </template>
            </v-btn>
          </v-container>
        </template>
      </Header>
      <v-navigation-drawer
        v-if="!isLandingPage() && this.$store.state.loggedIn"
        location="left"
        v-model="drawer"
        :permanent="this.smAndUp"
      >
        <v-list>
          <div
            v-for="routeGroup in visibleRouteGroups"
            :key="routeGroup.name"
            class="pb-4"
          >
              <v-list-subheader v-if="routeGroup.noTitle !== true">
                {{ (routeGroup.name) }}
              </v-list-subheader>
              <v-list-item
                v-for="route in routeGroup.routes"
                :key="route.path"
                :value="route.path"
                :to="route.path"
              >
                <template v-slot:prepend>
                  <v-icon :icon='route.icon'>
                  </v-icon>
                </template>
                <v-list-item-title v-text="route.name" class="pl-2 text-decoration-none" >
                </v-list-item-title>
              </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container v-if="!isLandingPage()" class="px-4 mt-4 pb-16" :style='!isLandingPage() && !this.$store.state.loggedIn ? "max-width: 800px" : ""' >
          <router-view  :key="$route.fullPath" />
        </v-container>
        <router-view v-else />
      </v-main>
    </v-layout>
  </v-app>
</template>

<style>
@import './styles/vueGoodTable.css';
@import './styles/global.css';
@import './styles/vuetifyOverrides.css';

.grecaptcha-badge { visibility: hidden; }
a {
  color: #4485ca;
  text-decoration: none;
}
</style>

<script>
import Header from '@/fragments/Header.vue';
import { useDisplay } from 'vuetify';
import CookieConsentBanner from '@/components/CookieConsentBanner.vue';
import { getVisibleRouteGroups } from './config/router';

export default {
  name: 'App',
  components: { CookieConsentBanner, Header },
  setup() {
    const { smAndUp } = useDisplay();

    return { smAndUp };
  },
  data: () => ({
    drawer: true,
  }),
  computed: {
    visibleRouteGroups() {
      return getVisibleRouteGroups(this.$store.state.loggedIn);
    },
  },
  methods: {
    isLandingPage() {
      return this.$route.path === '/';
    },
  },
};
</script>
