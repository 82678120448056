<template>
  <v-autocomplete
    item-title="name"
    v-model="autocompleteModel"
    :items="geoBaseTypesList"
    :error-messages="geoBaseTypeErrors"
    label="Geo Type"
    @blur="v$.geoBaseTypes?.$touch()"
    :auto-select-first="false"
    :return-object="true"
    :multiple="false"
  />
</template>

<script>

import axios from 'axios';
import { geoBaseTypesAsList } from '@/config/geoBaseTypes';

export default {
  name: 'GeoBaseTypeSelection',
  props: {
    selectedValue: Number,
    geoBaseTypeErrors: Array,
    v$: Object,
  },
  data() {
    return {
      geoBaseTypesList: geoBaseTypesAsList(),
    };
  },
  computed: {
    autocompleteModel: {
      get() {
        return (this.selectedValue != null && this.geoBaseTypesList.find((geoBaseType) => geoBaseType.id === this.selectedValue)) || null;
      },
      set(value) {
        if (this.v$.geoBaseTypes) {
          this.v$.geoBaseTypes.$touch();
        }
        this.$emit('update:selectedValue', value.id);
      },
    },
  },
};
</script>
