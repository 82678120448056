<template>
  <GeoEntityView :geo-base-type="this.geoBaseType" info-text-general="Manage destination">
  </GeoEntityView>
</template>

<script>

import GeoEntityView from '@/views/GeoEntityView.vue';
import { geoBaseTypes } from '@/config/geoBaseTypes';

export default {
  components: {
    GeoEntityView,
  },
  data() {
    return ({
      geoBaseType: geoBaseTypes.DESTINATION,
    });
  },

};
</script>
