<template>
    <page-top-area header-text="Activate your account">
      <template v-slot:button-area>
      </template>
    </page-top-area>
    <v-row justify="space-between">
      <v-col
        cols="12"
      >
        <div v-if="!confirmed">
          Please click to confirm the registration with your e-mail address. Only after activation you can login.
        </div>
        <div v-else>
          Thank you for confirming your e-mail address. You can now login.
          <br />
          <br />
          Redirecting in 3 seconds to
          <span style="color: #4485ca">
            <router-link to="/login">
              login...
            </router-link>
          </span>
        </div>
      </v-col>
      <v-col
        cols="12"
      >
        <v-container class="d-flex justify-center">
          <v-btn
            v-if="!confirmed"
            :loading="loading"
            @click="handleConfirm"
            color="primary"
          >
            Confirm E-Mail
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
    <RequestErrorNotification :error="submitError" />
</template>

<script>
import axios from 'axios';
import RequestErrorNotification from '@/components/RequestErrorNotification.vue';
import { getCRMBackendHost } from '@/utils/urlUtils';

export default {
  components: {
    RequestErrorNotification,
  },
  mounted() {
  },
  data() {
    return ({
      loading: false,
      registrationToken: this.$route.query.token,
      submitError: null,
      confirmed: false,
    });
  },
  methods: {
    handleConfirm() {
      this.loading = true;
      this.submitError = null;

      const requestBody = {
        token: this.registrationToken,
      };

      axios.post(`${getCRMBackendHost()}/registration-confirm`, requestBody)
        .then(() => {
          this.confirmed = true;
          console.log(111);
          setTimeout(() => {
            this.$router.push('/login');
          }, 3000);
        }).catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
  },
};
</script>
