<template>
  <v-dialog
    v-model="modalOpen"
    max-width="800"
  >
    <v-card class="m-0">
      <v-card-text>
        Generate content with ai
      </v-card-text>
      <v-card-item>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="entityName"
              label="For what?"
              required
            />
          </v-col>
          <v-col cols="4" class="d-flex align-center">
            <v-select
              label="Request type?"
              v-model="selectedRequestType"
              :items="this.requestTypesAsList"
            ></v-select>
          </v-col>
          <v-col cols="4" class="d-flex align-content-end justify-end pb-2 ">
            <v-btn
              color="primary"
              @click="onGenerate"
              :disabled="this.loading"
              size="large"
              >
              Generate
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="generatedContent"
              :counter="2500"
              label="Generated content"
              required
              auto-grow
            />
          </v-col>
        </v-row>
      </v-card-item>
      <v-card-actions>
        <v-row justify="space-between">
          <v-col
            cols="4"
          >
            <v-btn
              color="primary"
              @click="handleCopy"
            >
             Copy to clipboard
            </v-btn>
          </v-col>
          <v-col
            cols="4"
          >
<!--            <v-btn-->
<!--              color="primary"-->
<!--              @click="handleConfirm"-->
<!--            >-->
<!--             Add content-->
<!--            </v-btn>-->
          </v-col>
          <v-col
            cols="4"
          >
            <v-btn
              class="float-right"
              @click="onAbort"
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <RequestErrorNotification :error="submitError"/>
</template>

<script>

import RequestErrorNotification from '@/components/RequestErrorNotification.vue';
import { requestTypesAsList } from '@/config/textWizardRequestTypes';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';

export default {
  name: 'AiWizardTextDialog',
  components: { RequestErrorNotification },
  props: {
    open: Boolean,
    onAbort: Function,
    onConfirm: Function,
    initialEntityName: String,
    initialRequestType: String,
    geoBaseType: Object,
    countryId: Number,
  },
  data() {
    return {
      generatedContent: null,
      entityName: '',
      submitError: null,
      loading: false,
      selectedRequestType: this.initialRequestType,
      requestTypesAsList: requestTypesAsList(),
    };
  },
  computed: {
    modalOpen: {
      get() {
        return this.open;
      },
      set() {
      },
    },
  },
  created() {
  },
  watch: {
    initialEntityName() {
      this.generatedContent = '';
      this.entityName = this.initialEntityName;
    },
    initialRequestType() {
      this.generatedContent = '';
      this.selectedRequestType = this.initialRequestType;
    },
  },
  methods: {
    onGenerate() {
      this.loading = true;
      axios.post(`${getBackendAdminHost(this.$store)}/admin/ai-wizard/${this.geoBaseType.id}/generate-content`, this.buildRequest(), { withCredentials: true })
        .then((response) => {
          this.generatedContent = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.submitError = 'Error while generating content';
        }).finally(() => {
          this.loading = false;
        });
    },
    buildRequest() {
      return {
        entityName: this.entityName,
        countryId: this.countryId,
        requestType: this.selectedRequestType,
      };
    },
    handleConfirm() {
      this.onConfirm(this.generatedContent);
    },
    handleCopy() {
      this.submitError = 'Copied!';
      navigator.clipboard.writeText(this.generatedContent);
      this.onAbort();
    },
  },
};
</script>
