<template>
  <div>
    <page-top-area header-text="Destinations" info-text="Manage all destinations">
      <template v-slot:button-area>
        <v-btn
          prepend-icon="mdi-plus-circle"
          @click="handleCreate"
          variant="text"
          color="#4485ca"
        >
          New destination
        </v-btn>
        <v-btn
          prepend-icon="mdi-plus-circle"
          @click="handleImport"
          variant="link"
          color="#4485ca"
        >
          Generate
        </v-btn>
      </template>
    </page-top-area>
    <GeoEntityListView :geo-base-type="this.geoBaseType" />
  </div>
</template>

<script>

import GeoEntityListView from '@/views/GeoEntityListView.vue';
import PageTopArea from '@/components/PageTopArea.vue';
import { geoBaseTypes } from '@/config/geoBaseTypes';

export default {
  components: { PageTopArea, GeoEntityListView },
  methods: {
    handleCreate() {
      this.$router.push('/destination/new');
    },
    handleImport() {
      this.$router.push('/import/new-entries/destination');
    },
  },
  data() {
    return {
      geoBaseType: geoBaseTypes.DESTINATION,
    };
  },
};
</script>
