<template>
  <v-row v-if="images">
    <v-col cols="3" v-for="{path, uuid, active, order} in images" :key="path + order">
      <v-img :src=this.getImagePath(path)
             :style="{cursor: 'pointer', border: (uuid === this.selectedImageUuid ? '2px solid black' : '')}"
             @click="handleSelectImage(uuid)"></v-img>
      <v-container v-if="uuid != null && this.selectedImageUuid === uuid" class="flex-row d-flex">
        <v-btn
          @click="handleSetFirst(uuid)"
          v-if="this.images.length > 1"
          class="mx-1"
          color="primary"
          size="small"
        >
          As first
        </v-btn>
        <v-btn
          @click="handleRemoveFile(uuid)"
          :disabled="this.loading"
          v-if="path.includes('http')"
          class="mx-1"
          size="small"
        >
          Remove
        </v-btn>
        <v-btn
          @click="handleDeactivate(uuid)"
          :disabled="this.loading"
          class="mx-1"
          v-else-if="active"
          size="small"
        >
          Deactivate
        </v-btn>
        <v-btn
          @click="handleActivate(uuid)"
          :disabled="this.loading"
          class="mx-1"
          v-else-if="!active"
          color="primary"
          size="small"
        >
          Activate
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
  <v-row class="mt-8">
    <v-col cols="3">
      <div class="text-subtitle-1 mt-3">
        Select image from local:
      </div>
    </v-col>
    <v-col
      cols="6"
    >
      <v-file-input placeholder="Upload new image" v-model="fileUpload" truncate-length="15"/>
    </v-col>
    <v-col
      cols="3"
    >
      <v-container :style="{display: 'flex', justifyContent: 'flex-end', paddingTop: '10px'}">
        <v-btn
          @click="handleUploadFile"
          :disabled="!this.fileUpload || this.loading"
          color="primary"
        >
          Upload
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
  <RequestErrorNotification :error="submitError" />
</template>

<script>

import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';

export default {
  name: 'ImageUpload',
  props: {
    uploadPath: String,
    reloadFunction: Function,
    v$: Object,
    images: Array,
  },
  mounted() {
  },
  updated() {
  },
  data() {
    return {
      loading: false,
      newImages: [],
      fileUpload: '',
      selectedImageUuid: null,
      submitError: null,
    };
  },
  methods: {
    getImagePath(path) {
      if (path.includes('http')) {
        return path;
      }
      return `http://localhost/${path}`;
    },
    handleUploadFile() {
      this.loading = true;
      const formData = new FormData();
      formData.append('file', this.fileUpload[0]);
      axios.post(this.uploadPath, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          this.reloadFunction(response);
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
          this.fileUpload = '';
        });
    },
    handleSelectImage(uuid) {
      this.selectedImageUuid = uuid;
    },
    handleSetFirst(uuid) {
      this.loading = true;
      axios.post(`${getBackendAdminHost(this.$store)}/admin/image-config/${uuid}/first`, {}, {
        withCredentials: true,
      })
        .then(() => {
          this.reloadFunction();
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
        });
    },
    handleDeactivate(uuid) {
      this.loading = true;
      axios.post(`${getBackendAdminHost(this.$store)}/admin/image-config/${uuid}/deactivate`, {}, {
        withCredentials: true,
      })
        .then(() => {
          this.reloadFunction();
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
        });
    },
    handleActivate(uuid) {
      this.loading = true;
      axios.post(`${getBackendAdminHost(this.$store)}/admin/image-config/${uuid}/activate`, {}, {
        withCredentials: true,
      })
        .then(() => {
          this.reloadFunction();
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
        });
    },
    handleRemoveFile(uuid) {
      this.loading = true;
      axios.delete(`${getBackendAdminHost(this.$store)}/admin/image/${uuid}`, {
        withCredentials: true,
      })
        .then(() => {
          this.reloadFunction();
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    autocompleteModel: {
      get() {
        return !this.loading && this.countryList.find((c) => c.id === this.selectedValue).name;
      },
      set(value) {
        this.v$.countryId.$touch();
        this.$emit('update:selectedValue', this.countryList.find((c) => c.name === value?.name).id);
      },
    },
  },
};
</script>
