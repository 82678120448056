<template>
  <GeoEntityView :geo-base-type="this.geoBaseType"
                 info-text-general="All ways"
                 :additional-fields="this.additionalFields"
  >
    <template v-slot:additionalFields>
      <RouteSelection
        v-model:selectedValue="this.routeUuid"
        :v$="v$"
      />
    </template>
  </GeoEntityView>
</template>

<script>

import GeoEntityView from '@/views/GeoEntityView.vue';
import { geoBaseTypes } from '@/config/geoBaseTypes';
import RouteSelection from '@/components/RouteSelection.vue';
import useVuelidate from '@vuelidate/core';

export default {
  components: {
    RouteSelection,
    GeoEntityView,
  },
  data() {
    return ({
      // v$: useVuelidate(),
      geoBaseType: geoBaseTypes.WAY,
      routeUuid: null,
      additionalFields: this.createAdditionalFields(),
    });
  },
  methods: {
    createAdditionalFields() {
      return [
        {
          name: 'routeUuid',
          value: this.routeUuid,
          requireConditionFit: () => this.routeUuid !== null,
          callbackLoaded: (newValue) => {
            this.routeUuid = newValue;
          },
        },
      ];
    },
  },
  computed: {

  },
  watch: {
    routeUuid(newVal, oldVal) {
      this.additionalFields = this.createAdditionalFields();
    },
  },
  validations: {
    routeUuid: {
      required: {
        message: 'Route is required',
      },
    },
  },

};
</script>
