<template>
  <LoaderPlaceholder
    v-if="this.loading"
  />
  <div v-else-if="this.entries.length==0">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            No {{this.geoBaseType.name}}s found.
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
  <div v-else >
    <vue-good-table
      :columns="this.columns"
      :rows="this.entries"
      :pagination-options="{
        enabled: true,
        perPage: 30,
        perPageDropdown: [15, 30, 50, 100],
        dropdownAllowAll: false,
      }"
      :search-options="{
        enabled: true,
      }"
      styleClass="vgt-table"
    >
      <template #table-row="props">
        <span v-if="props.column.field === 'name'">
          <router-link :to="'/'+this.geoBaseType.path+'/'+props.row.uuid">
            <div class="text-subtitle-1">
              {{ props.row.name }}
            </div>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'active'" class="m-n4">
           <v-checkbox-btn
             v-model="props.row.active"
             @click.stop="handleSwitchActive($event, props.row.uuid)"
             density="compact"
           >
           </v-checkbox-btn>
        </span>
        <span v-else-if="props.column.field === 'swiping'" class="m-n4">
           <v-checkbox-btn
             v-model="props.row.swiping"
             @click.stop="handleSwitchSwiping($event, props.row.uuid)"
             density="compact"
           >
           </v-checkbox-btn>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
<!--  <v-btn-->
<!--    class="float-right"-->
<!--    variant="text"-->
<!--    @click="()=> handleOpenWizard(this.requestTypes.COMPLETE)"-->
<!--    color="#4485ca"-->
<!--  >-->
<!--    Use wizard-->
<!--  </v-btn>-->
  <AiWizardCompleteDialog
    :open="this.wizardModalOpen"
    :onAbort="handleCloseWizard"
    :initialEntityName="this.name"
    :initialRequestType="this.wizardRequestType"
    :geo-base-type="this.geoBaseType"
  />
  <RequestErrorNotification :error="submitError" />
</template>

<script>
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import AiWizardCompleteDialog from '@/components/AiWizardCompleteDialog.vue';
import { completeWizardRequestTypes } from '@/config/completeWizardRequestTypes';

export default {
  components: { AiWizardCompleteDialog },
  props: {
    geoBaseType: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: false,
      default: () => [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Country',
          field: 'countryName',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Public',
          field: 'active',
          type: 'boolean',
        },
        {
          label: 'Swiping',
          field: 'swiping',
          type: 'boolean',
        },
        {
          label: 'Rating',
          field: 'rating',
          type: 'number',
        },
      ],
    },
  },
  data() {
    return {
      loading: true,
      submitError: null,
      requestTypes: completeWizardRequestTypes,
      wizardModalOpen: false,
      entries: [
      ],
    };
  },
  methods: {
    handleSwitchActive(event, entryUuid) {
      event.preventDefault();
      axios.post(`${getBackendAdminHost(this.$store)}/admin/${this.geoBaseType.path}/${entryUuid}/switch-active`, {}, { withCredentials: true })
        .then(() => {
          const changedEntry = this.entries.find((d) => d.uuid === entryUuid);
          changedEntry.active = !changedEntry.active;
          this.entries = [...this.entries]; // update
        }).catch(() => {
          this.submitError = 'Error switching active state';
        });
    },
    handleSwitchSwiping(event, entryUuid) {
      event.preventDefault();
      axios.post(`${getBackendAdminHost(this.$store)}/admin/${this.geoBaseType.path}/${entryUuid}/switch-swiping`, {}, { withCredentials: true })
        .then(() => {
          const changedEntry = this.entries.find((d) => d.uuid === entryUuid);
          changedEntry.swiping = !changedEntry.swiping;
          this.entries = [...this.entries]; // update
        }).catch(() => {
          this.submitError = 'Error switching swiping state';
        });
    },
    handleOpenWizard(requestType) {
      this.wizardRequestType = requestType;
      this.wizardModalOpen = true;
    },
    handleCloseWizard() {
      this.wizardModalOpen = false;
    },
  },
  mounted() {
    this.axios.get(`${getBackendAdminHost(this.$store)}/admin/${this.geoBaseType.path}`, { withCredentials: true })
      .then((response) => {
        this.entries = response.data;
        this.loading = false;
      }).catch(() => {
        this.submitError = 'Error during request';
      });
  },
};
</script>
