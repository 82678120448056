<template>
  <v-app-bar
    elevation="4"
    flat
    color="#4485ca"
  >
    <v-container fluid class="d-flex pa-1">
      <v-container style="max-width: 300px; ">
          <v-container style="display: flex; padding: 0; align-items: center;">
            <span>
              <slot v-if="!isLandingPage()" name="menu-hamburger-button"></slot>
              <v-img
                style="margin-right: 20px"
                :width="34"
                :aspect-ratio="1"
                src="/logo.svg"
                :class="this.isLandingPage() ? '' :'hidden-xs'"
              ></v-img>
            </span>
            <span class="hidden-xs">
              <a href="/" class="text-decoration-none text-white text-body-1 text-sm-h6">
                Your Travel Itinerary
              </a>
            </span>
          </v-container>
      </v-container>
      <v-container fluid class="d-flex justify-end align-center" v-if="this.isLoggedIn">
        <v-btn color="#4485ca" @click="handleDashboard" class="text-body-2" v-if="this.isLandingPage()">
          <template v-slot:prepend>
            <v-icon color="white">mdi-table </v-icon>
          </template>
          <span class="text-decoration-none text-white">
            Dashboard
          </span>
        </v-btn>
        <div v-else class="justify-center align-center d-flex">
          <v-btn color="#4485ca" @click="handlePreview" class="text-body-2" >
            <template v-slot:prepend>
              <v-icon color="white">mdi-earth </v-icon>
            </template>
            <span class="text-decoration-none text-white">Preview</span>
          </v-btn>
          <v-btn color="#4485ca" @click="handleLogout" class="text-body-2">
            <template v-slot:prepend>
              <v-icon color="white">mdi-logout-variant</v-icon>
            </template>
            <span class="text-decoration-none text-white">Logout</span>
          </v-btn>
        </div>
      </v-container>
      <v-container fluid
                   class="d-flex justify-end align-center"
                   v-else>
        <v-btn v-if="this.$route.path!=='/register'" color="#4485ca" @click="handleRegister" class="text-body-2">
          <template v-slot:prepend>
            <v-icon color="white">mdi-account</v-icon>
          </template>
          <span class="text-decoration-none text-white">Register</span>
        </v-btn>
        <v-btn v-if="this.$route.path!=='/login'" color="#4485ca" @click="handleLogin" class="text-body-2">
          <template v-slot:prepend>
            <v-icon color="white">mdi-login-variant</v-icon>
          </template>
          <span class="text-decoration-none text-white">Login</span>
        </v-btn>
      </v-container>
    </v-container>
  </v-app-bar>
</template>

<script>

import axios from 'axios';
import { getCRMBackendHost, getCurrentSubdomain } from '@/utils/urlUtils';

export default {
  name: 'HeaderComponent',
  data: () => ({
    //
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.loggedIn;
    },
  },
  methods: {
    async handleLogout() {
      axios.post(`${getCRMBackendHost()}/logout`, {}, { withCredentials: true })
        .then(() => {

        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$router.push('/');
        });
      this.$store.commit('logout');
    },
    handlePreview() {
      window.open(getCurrentSubdomain(this.$store.state), '_blank');
    },
    handleDashboard() {
      this.$router.push('/admin-overview');
    },
    handleLogin() {
      this.$router.push('/login');
    },
    handleRegister() {
      this.$router.push('/register');
    },
    isLandingPage() {
      return this.$route.path === '/';
    },
  },
};
</script>
