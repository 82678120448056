<template>
  <v-autocomplete
    item-title="question"
    :disabled="this.loading"
    v-model="autocompleteModel"
    :items="infoCardTemplateList"
    :error-messages="infoCardTemplateErrors"
    label="Info Card Template"
    @blur="v$.infoCardTemplate?.$touch()"
    :auto-select-first="true"
    :return-object="true"
  />
</template>

<script>

import axios from 'axios';
import { startCase } from 'lodash';
import { getBackendAdminHost, getBackendHost } from '@/utils/urlUtils';

export default {
  name: 'InfoCardTemplateSelection',
  props: {
    geoBaseUuid: String,
    selectedValue: String,
    infoCardTemplate: Array,
    v$: Object,
  },
  mounted() {
    axios.get(`${getBackendAdminHost(this.$store)}/admin/info-card-template-link/by-geo-base-uuid/${this.geoBaseUuid}`, { withCredentials: true })
      .then((response) => {
        this.infoCardTemplateList = [this.noSelection, ...response.data];
        this.loading = false;
      });
  },
  data() {
    return {
      loading: true,
      infoCardTemplateList: [],
      noSelection: {
        uuid: null,
        question: 'No Selection',
      },
    };
  },
  computed: {
    autocompleteModel: {
      get() {
        return (this.selectedValue != null && this.infoCardTemplateList.find((template) => template.uuid === this.selectedValue)) || null;
      },
      set(value) {
        if (this.v$.infoCardTemplate) {
          this.v$.infoCardTemplate.$touch();
        }
        this.$emit('update:selectedValue', value.uuid);
      },
    },
  },
};
</script>
