<template>
  <v-card>
    <v-card-title>
     <span v-if="this.editMode">
        <v-text-field
          v-model="this.name"
          label="Name"
          outlined
          :error-messages="this.nameErrors"
          required
        />
       <QuillTextarea v-if="!this.loading" v-model:content="this.description" placeholder="Description"/>
      </span>
      <span v-else>
        {{
          this.name
        }}
        <span v-if="this.routeData.startRoutePlace || this.routeData.startGeoBase?.uuid" class="text-subtitle-2">
          {{ ' (' + (this.routeData.startRoutePlace || this.routeData.startGeoBase)?.name + ' - ' + (this.routeData.endRoutePlace || this.routeData.endGeoBase)?.name + ')' }}
        </span>
        <v-btn icon @click="handleEdit" class="float-end" size="small">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-row v-if="this.description">
          <v-col cols="12" class="my-2">
              <span class="text-body-1" v-html=this.description />
          </v-col>
        </v-row>
       </span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <span v-if="this.editMode && !this.onlyNameAndDescriptionEditable">
            <v-text-field
              v-model="this.costs"
              label="Costs"
              outlined
              :error-messages="this.costsErrors"
              required
            />
          </span>
          <span v-else>
            <span v-if="this.costs">
              <span class="text-caption">Costs: </span>
              <span class="text-body-1">${{ this.costs }}</span>
            </span>
          </span>
        </v-col>
        <v-col cols="4">
          <span v-if="this.editMode && !this.onlyNameAndDescriptionEditable">
            <v-text-field
              v-model="this.costsMin"
              label="Minimum"
              outlined
              :error-messages="this.costsMinErrors"
              required
            />
          </span>
          <span v-else>
            <span v-if="this.costsMin">
              <span class="text-caption">Minimum: </span>
              <span class="text-body-1">${{ this.costsMin }}</span>
            </span>
          </span>
        </v-col>
        <v-col cols="4">
          <span v-if="this.editMode && !this.onlyNameAndDescriptionEditable">
            <v-text-field
              v-model="this.costsMax"
              label="Maximum"
              outlined
              :error-messages="this.costsMaxErrors"
              required
            />
          </span>
          <span v-else>
            <span v-if="this.costsMax">
              <span class="text-caption">Maximum: </span>
              <span class="text-body-1">${{ this.costsMax }}</span>
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <span v-if="this.editMode && !this.onlyNameAndDescriptionEditable">
            <v-text-field
              v-model="this.duration"
              label="Duration"
              outlined
              :error-messages="this.durationErrors"
              required
            />
          </span>
          <span v-else>
            <span v-if="this.duration">
              <span class="text-caption">Duration: </span>
              <span class="text-body-1">{{ this.duration }} min</span>
            </span>
          </span>
        </v-col>
        <v-col cols="4">
          <span v-if="this.editMode && !this.onlyNameAndDescriptionEditable">
            <v-text-field
              v-model="this.durationMin"
              label="Minimum"
              outlined
              :error-messages="this.durationMinErrors"
              required
            />
          </span>
          <span v-else>
            <span v-if="this.durationMin">
              <span class="text-caption">Minimum: </span>
              <span class="text-body-1">{{ this.durationMin }} min</span>
            </span>
          </span>
        </v-col>
        <v-col cols="4">
          <span v-if="this.editMode && !this.onlyNameAndDescriptionEditable">
            <v-text-field
              v-model="this.durationMax"
              label="Maximum"
              outlined
              :error-messages="this.durationMaxErrors"
              required
            />
          </span>
          <span v-else>
            <span v-if="this.durationMax">
              <span class="text-caption">Maximum: </span>
              <span class="text-body-1">{{ this.durationMax }} min</span>
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <span v-if="this.editMode">
            <TransferTypeSelection :v$="v$" v-model="this.transferType" />
          </span>
          <span v-else>
            <span v-if="this.transferType">
              <span class="text-caption">Type: </span>
              <span class="text-body-1">{{ this.transferType.name }}</span>
            </span>
          </span>
        </v-col>
        <v-col cols="4">
          <span v-if="this.editMode">
            <AgencySelection :v$="v$" v-model:selected-values="this.agencies" />
          </span>
          <span v-else>
            <span v-if="this.agencies.length > 0">
              <span class="text-caption">Agencies: </span>
              <span class="text-body-1">{{ this.agenciesNames.join(", ")}}</span>
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="this.editMode">
        <v-col cols="12">
          <v-btn color="primary" @click="handleSave">Save</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import { getErrorMessages } from '@/utils/validationUtils';
import { maxLength, minLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import QuillTextarea from '@/components/QuillTextarea.vue';
import { mapTransferTypeId } from '@/config/transferTypes';
import TransferTypeSelection from '@/components/TransferTypeSelection.vue';
import AgencySelection from '@/components/AgencySelection.vue';

export default {
  name: 'RouteDataCard',
  components: { AgencySelection, TransferTypeSelection, QuillTextarea },
  props: {
    uuid: String,
    routeData: Object,
    handleRefreshData: Function,
    onlyNameAndDescriptionEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: this.routeData.name,
      description: this.routeData.description,
      costsValue: this.routeData.costs,
      costsMin: this.routeData.costsMin,
      costsMax: this.routeData.costsMax,
      costs: this.routeData.costs,
      duration: this.routeData.duration,
      durationMin: this.routeData.durationMin,
      durationMax: this.routeData.durationMax,
      transferType: mapTransferTypeId(this.routeData.transferType !== undefined ? this.routeData.transferType : this.routeData.transferTypeId),
      loading: false,
      submitError: null,
      editMode: false,
      v$: useVuelidate(),
      agencies: [...(this.routeData.agencies || []).map((t) => t.uuid)],
      agenciesNames: [...(this.routeData.agencies || []).map((t) => t.name)],
      fileUploadPath: null,
    };
  },
  validations: {
    name: {
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    description: {
      maxLength: maxLength(50),
      minLength: minLength(0),
    },
    costs: {},
    costsMin: {},
    costsMax: {},
    duration: {},
    durationMin: {},
    durationMax: {},
    agencies: {},
  },
  computed: {
    nameErrors() {
      return getErrorMessages(this.v$.name, 'Name');
    },
    descriptionErrors() {
      return getErrorMessages(this.v$.description, 'Description');
    },
    costsErrors() {
      return getErrorMessages(this.v$.costs, 'Costs');
    },
    costsMinErrors() {
      return getErrorMessages(this.v$.costsMin, 'Costs Min');
    },
    costsMaxErrors() {
      return getErrorMessages(this.v$.costsMax, 'Costs Max');
    },
    durationErrors() {
      return getErrorMessages(this.v$.duration, 'Duration');
    },
    durationMinErrors() {
      return getErrorMessages(this.v$.durationMin, 'Duration Min');
    },
    durationMaxErrors() {
      return getErrorMessages(this.v$.durationMax, 'Duration Max');
    },
  },
  methods: {
    buildRequestObject() {
      return {
        name: this.name,
        description: this.description,
        duration: this.duration,
        durationMin: this.durationMin,
        durationMax: this.durationMax,
        costs: this.costs,
        costsMin: this.costsMin,
        costsMax: this.costsMax,
        transferTypeId: this.transferType.id,
        agencies: this.agencies,
      };
    },
    handleSave() {
      axios.put(`${getBackendAdminHost(this.$store)}/admin/route/${this.uuid}`, this.buildRequestObject(), { withCredentials: true })
        .then(() => {
          this.handleRefreshData();
          this.editMode = false;
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleEdit() {
      this.editMode = true;
    },
    touchIgnoreableElements() {
      if (this.v$.agencies) {
        this.v$.agencies.$touch();
      }
    },
  },
  created() {
    this.touchIgnoreableElements();
  },
};
</script>
