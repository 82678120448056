export const getValidateFunctionsErrors = (otherValidations) => {
  if (!otherValidations?.length) {
    return [];
  }

  return otherValidations.map((otherValidation) => otherValidation()).filter((otherValidation) => otherValidation != null);
};

export const getErrorMessages = (field, fieldName, otherValidations) => {
  if (!field.$dirty) {
    return [];
  }

  const errors = getValidateFunctionsErrors(otherValidations);
  if (field.minLength?.$invalid) {
    errors.push(`${fieldName} must be at least ${field.minLength.$params.min} characters long`);
  }
  if (field.maxLength?.$invalid) {
    errors.push(`${fieldName} must be at most ${field.maxLength.$params.max} characters long`);
  }
  if (field.required?.$invalid) {
    errors.push(`${fieldName} is required.`);
  }
  if (field.email?.$invalid) {
    errors.push(`${fieldName} must be an E-Mail`);
  }
  if (field.sameAs?.$invalid) {
    errors.push(`${fieldName} must be the same as above`);
  }
  if (field.passwordFormat?.$invalid) {
    errors.push(`${fieldName} must contain at least one lowercase letter, one uppercase letter, one number and one special character`);
  }
  return errors;
};

export const checkPasswordFormat = (password) => {
  const regex = /^(?=.*[a-z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
  return regex.test(password);
};

export const minLengthWithoutHtml = (min) => (value) => {
  if (value == null) {
    return false;
  }
  const regex = /(<([^>]+)>)/gi;
  const text = value.replace(regex, '');
  return text.length >= min;
};
