<template>
  <LoaderPlaceholder
    v-if="this.loading"
  />
  <form v-else>
    <v-tabs
      v-model="tab"
      class="mb-4"
      background-color="primary"
      center-active
      v-if="!this.isNew()"
    >
      <v-tab value="one">General</v-tab>
      <v-tab value="links">Links</v-tab>
    </v-tabs>
    <v-window v-model="tab" class="py-2">
      <v-window-item value="one">
        <page-top-area
          :header-text='this.isNew() ? "New info card template" : "Edit " + this.question.substring(0, 50)'
          :info-text="'Manage an info card template'">
          <template v-slot:button-area>
            <v-row>
              <v-col cols="6">
              </v-col>
              <v-col cols="6">
                <v-btn
                  prepend-icon="mdi-delete"
                  variant="text"
                  color="#4485ca"
                  @click="handleClickDelete"
                >
                  Delete
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </page-top-area>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="question"
              :error-messages="questionErrors"
              :counter="10"
              label="Question"
              required
              @input="v$.question.$touch()"
              @blur="v$.question.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="aiQuestion"
              :error-messages="aiQuestionErrors"
              :counter="10"
              label="Ai Question"
              required
              @input="v$.aiQuestion.$touch()"
              @blur="v$.aiQuestion.$touch()"
            />
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col
            cols="6"
          >
            <v-btn
              v-if="this.isNew() === false"
              @click="()=>handleSave(true)"
              :disabled="(this.isNew() && !v$.$dirty)  || v$.$error"
              color="primary"
            >
              Save & force update
            </v-btn>
          </v-col>
          <v-col
            cols="6"
          >
            <v-btn
              @click="()=>handleSave(false)"
              :disabled="(this.isNew() && !v$.$dirty)  || v$.$error"
              color="primary"
            >
              Only Save
            </v-btn>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item value="links">
        <v-row>
          <v-col cols="12">
            <vue-good-table
              :columns="this.linkColumns"
              :rows="this.links"
              :pagination-options="{
                enabled:false
              }"
              styleClass="vgt-table"
            >
              <template #table-row="props">
                <span v-if="props.column.field === 'tags'">
                  <div class="text-caption">
                    {{ props.row.tags }}
                  </div>
                </span>
                <span v-else-if="props.column.field === 'delete'">
                  <v-btn
                    icon
                    @click="handleClickDeleteLink(props.row.uuid)"
                    size="small"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <GeoBaseTypeSelection
              v-model:selectedValue="newLinkGeoBaseType"
              :v$="v$"
            />
          </v-col>
          <v-col cols="4">
            <TagSelection
              v-model:selectedValues="newLinkTags"
              :v$="v$"
            />
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-end">
            <v-btn
              @click="handleAddLink"
              :disabled="newLinkGeoBaseType === null"
              color="primary"
            >
              Add Link
            </v-btn>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </form>
  <ConfirmationDialog
    :open="deleteModalOpen"
    :onAbort="handleAbortDeleteModal"
    :onConfirm="handleConfirmDeleteModal"
    :text="'Do you really want to delete?'"
  />
  <RequestErrorNotification :error="submitError"/>
</template>

<script>
import {
  maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getErrorMessages } from '@/utils/validationUtils';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import PageTopArea from '@/components/PageTopArea.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import TagSelection from '@/components/TagSelection.vue';
import GeoBaseTypeSelection from '@/components/GeoBaseTypeSelection.vue';
import { mapGeoBaseTypeId } from '@/config/geoBaseTypes';
import { de } from 'vuetify/locale';

export default {
  components: {
    GeoBaseTypeSelection,
    TagSelection,
    PageTopArea,
    ConfirmationDialog,
  },
  validations: {
    question: {
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    aiQuestion: {
      maxLength: maxLength(50),
      minLength: minLength(0),
    },
    newLinkGeoBaseType: {},
    newLinkTags: {},
  },
  mounted() {
    this.loadBasicData();
    this.loadLinks();
    this.touchIgnoreableElements();
  },
  data() {
    return ({
      loading: !this.isNew(),
      submitError: null,
      uuid: this.isNew() ? null : this.$route.params.uuid,
      v$: useVuelidate(),
      tab: null,
      deleteModalOpen: false,
      question: '',
      aiQuestion: '',
      links: [],
      newLinkGeoBaseType: null,
      newLinkTags: [],
      linkColumns: [
        {
          label: 'Geo Type',
          field: 'geoBaseType',
        },
        {
          label: 'Tags',
          field: 'tags',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: 'Delete',
          field: 'delete',
          filterOptions: {
            enabled: false,
          },
        },
      ],
    });
  },
  computed: {
    questionErrors() {
      return getErrorMessages(this.v$.question, 'Question');
    },
    aiQuestionErrors() {
      return getErrorMessages(this.v$.aiQuestion, 'Ai Question');
    },
  },
  methods: {
    isNew() {
      return this.$route.params.uuid === 'new';
    },
    loadBasicData() {
      if (!this.uuid) {
        return;
      }
      axios.get(`${getBackendAdminHost(this.$store)}/admin/info-card-template/${this.uuid}`, { withCredentials: true })
        .then((response) => {
          this.applyResponseData(response.data);
          this.loading = false;
        })
        .catch(() => {
          this.submitError = 'Error during request';
        });
    },
    applyResponseData(data) {
      this.question = data.question;
      this.aiQuestion = data.aiQuestion;
    },
    handleClickDelete() {
      this.deleteModalOpen = true;
    },
    handleAbortDeleteModal() {
      this.deleteModalOpen = false;
    },
    buildRequestObject(forceUpdateAllUsings) {
      return {
        question: this.question,
        aiQuestion: this.aiQuestion,
        forceUpdateAllUsings,
      };
    },
    create(requestBody) {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/info-card-template`, requestBody, { withCredentials: true })
        .then((response) => {
          this.loading = false;
          this.uuid = response.data.uuid;
          this.$router.push(`/info-card-template/${response.data.uuid}`);
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update(requestBody) {
      axios.put(`${getBackendAdminHost(this.$store)}/admin/info-card-template/${this.uuid}`, requestBody, { withCredentials: true })
        .then(() => {
          this.$router.push('/info-card-template');
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleConfirmDeleteModal() {
      axios.delete(`${getBackendAdminHost(this.$store)}/admin/info-card-template/${this.uuid}`, { withCredentials: true })
        .then(() => {
          this.$router.push('/info-card-template');
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    handleSave(forceUpdateAllUsings) {
      this.loading = true;

      const requestBody = this.buildRequestObject(forceUpdateAllUsings);

      if (this.isNew()) {
        this.create(requestBody);
      } else {
        this.update(requestBody);
      }
    },
    loadLinks() {
      if (!this.uuid) {
        return;
      }
      this.loading = true;
      axios.get(`${getBackendAdminHost(this.$store)}/admin/info-card-template-link/${this.uuid}`, { withCredentials: true })
        .then((response) => {
          this.links = response.data.map((link) => ({
            ...link,
            geoBaseType: mapGeoBaseTypeId(link.geoBaseTypeId).name,
            tags: link.tags.map((tag) => tag.name).join(', '),
          }));
          this.loading = false;
        })
        .catch(() => {
          this.submitError = 'Error during request';
        });
    },
    handleAddLink() {
      const requestObject = {
        infoCardTemplateUuid: this.uuid,
        geoBaseTypeId: this.newLinkGeoBaseType,
        tagUuidList: this.newLinkTags,
      };
      this.loading = true;

      axios.post(`${getBackendAdminHost(this.$store)}/admin/info-card-template-link`, requestObject, { withCredentials: true })
        .then(() => {
          this.newLinkGeoBaseType = null;
          this.newLinkTags = [];
          this.loadLinks();
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClickDeleteLink(linkUuid) {
      this.loading = true;
      axios.delete(`${getBackendAdminHost(this.$store)}/admin/info-card-template-link/${linkUuid}`, { withCredentials: true })
        .then(() => {
          this.loadLinks();
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    touchIgnoreableElements() {
      this.v$.aiQuestion.$touch();
    },
  },

};
</script>
