<template>
  <LoaderPlaceholder
    v-if="this.loading"
  />
  <form v-else>
    <v-row>
      <v-col>
        <h1 class="text-h5">
          Itinerary Collection
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="8"
      >
        <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          label="Itinerary name"
          required
          @input="v$.name.$touch()"
          @blur="v$.name.$touch()"
        />
      </v-col>
      <v-col
        cols="4"
      >
        <v-select
          label="Visbilility"
          v-model="selectedPublicValue"
          item-title="text"
          item-value="value"
          :items="this.publicValueList"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col>
        <h3 class="text-subtitle-1">
          Linked Itineraries
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <vue-good-table
          :columns="this.columns"
          :rows="this.itineraries"
          :pagination-options="{
          enabled: true,
          perPage: 10,
          perPageDropdown: [15, 30, 50, 100],
          dropdownAllowAll: false,
        }"
          :search-options="{
          enabled: true,
        }"
          styleClass="vgt-table"
        >
          <template #table-row="props">
            <span v-if="props.column.field === 'name'">
<!--              <a href ={{// `${props.row.uuid}`}} target="_blank">-->
                <div class="text-subtitle-1">
                  {{ props.row.name }}
                </div>
<!--              </a>-->
            </span>
            <span v-else-if="props.column.field === 'selected'">
              <div class="text-subtitle-1" v-if="this.itineraryIsSelected(props.row.id)">
                <v-checkbox
                  :model-value="true"
                  hide-details
                  @click="()=> this.handleRemoveItinerary(props.row)"
                ></v-checkbox>
              </div>
              <div v-else>
                <v-checkbox
                  :model-value="false"
                  hide-details
                  @click="()=> this.handleAddItinerary(props.row)"
                ></v-checkbox>
              </div>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </v-col>
    </v-row>
    <v-row v-if="isNew()">
      <v-col
        cols="6"
      >
        <v-btn
          class="float-right d-inline"
          :disabled="!v$.$dirty || v$.$error"
          :loading="loading"
          @click="handleCreate"
          color="primary"
        >
          Create
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!isNew()">
      <v-col cols="6">
        <v-btn
          class="float-left d-inline"
          :loading="loading"
          @click="handleDelete"
          color="primary"
        >
          Delete collection
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          class="float-right d-inline"
          :disabled="!v$.$dirty || v$.$error"
          :loading="loading"
          @click="handleUpdate"
          color="primary"
        >
          Update
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<script>

import { geoBaseTypes } from '@/config/geoBaseTypes';
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, required } from '@vuelidate/validators';
import axios from 'axios';
import { getBackendAdminHost, getCurrentSubdomain } from '@/utils/urlUtils';
import { getErrorMessages } from '@/utils/validationUtils';

export default {
  components: {},
  validations: {
    name: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
  },
  computed: {
    nameErrors() {
      return getErrorMessages(this.v$.name, 'Name');
    },
  },
  mounted() {
    this.loadItineraryCollection();
    this.loadItineraries();
  },
  data() {
    return ({
      v$: useVuelidate(),
      geoBaseType: geoBaseTypes.INFRASTRUCTURE,
      loading: false,
      geoBaseList: [],
      itineraries: [],
      selectedPublicValue: 0,
      publicValueList: [
        {
          text: 'Private',
          value: 0,
        },
        {
          text: 'Public',
          value: 1,
        }],
      name: '',
      linkedItineraries: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Selected',
          field: 'selected',
          filterOptions: {
            enabled: true,
          },
        },
      ],
    });
  },
  methods: {
    isNew() {
      return this.$route.params.id === 'new';
    },
    handleCreate() {
      axios.post(
        `${getBackendAdminHost(this.$store)}/admin/itineraries-collection`,
        this.buildCreateRequest(),
        { withCredentials: true },
      )
        .then(() => {
          this.$router.push('/itineraries-collections');
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleUpdate() {
      axios.put(
        `${getBackendAdminHost(this.$store)}/admin/itineraries-collection/${this.$route.params.id}`,
        this.buildCreateRequest(),
        { withCredentials: true },
      )
        .then(() => {
          this.loadItineraryCollection();
          this.submitError = 'Updated successfully';
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDelete() {
      axios.delete(
        `${getBackendAdminHost(this.$store)}/admin/itineraries-collection/${this.$route.params.id}`,
        { withCredentials: true },
      )
        .then(() => {
          this.$router.push('/itineraries-collections');
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildCreateRequest() {
      return {
        name: this.name,
        publicValue: this.selectedPublicValue,
        linkedItinerariesIds: this.linkedItineraries.map((itinerary) => itinerary.id),
      };
    },
    itineraryIsSelected(itineraryId) {
      return this.linkedItineraries.some((linkedItinerary) => linkedItinerary.id === itineraryId);
    },
    handleAddItinerary(itinerary) {
      this.linkedItineraries.push(itinerary);
    },
    handleRemoveItinerary(itinerary) {
      this.linkedItineraries = this.linkedItineraries.filter((linkedItinerary) => linkedItinerary.id !== itinerary.id);
    },
    applyLoadedItineraryCollection(itineraryCollectionResponse) {
      this.name = itineraryCollectionResponse.name;
      this.linkedItineraries = itineraryCollectionResponse.itinerariesResponse;
      this.selectedPublicValue = itineraryCollectionResponse.publicValue;
      this.v$.name.$touch();
    },
    loadItineraryCollection() {
      if (this.isNew()) {
        return;
      }
      this.loading = true;
      axios.get(`${getBackendAdminHost(this.$store)}/admin/itineraries-collection/${this.$route.params.id}`, { withCredentials: true })
        .then((response) => {
          this.applyLoadedItineraryCollection(response.data);
          this.loading = false;
        });
    },
    loadItineraries() {
      this.loading = true;
      axios.get(`${getBackendAdminHost(this.$store)}/admin/itinerary/managed`, { withCredentials: true })
        .then((response) => {
          this.itineraries = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

};
</script>
