export const transferTypes = {
  CAR: {
    id: 0,
    name: 'Car',
  },
  PUBLIC_TRANSPORT: {
    id: 1,
    name: 'Public transport',
  },
  WALK: {
    id: 2,
    name: 'Walk',
  },
  BIKE: {
    id: 3,
    name: 'Bike',
  },
};

export const transferTypesAsList = () => Object.values(transferTypes);

export const mapTransferTypeId = (id) => {
  const result = Object.values(transferTypes)
    .find((transferType) => transferType.id === id);
  if (result) {
    return result;
  }
  console.error('invalid transfer type id: ', id);
  return null;
};
