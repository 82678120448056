<template>
  <v-snackbar
    v-model="errorModel"
    :timeout="1000"
  >
    {{this.error}}
  </v-snackbar>
</template>

<script>
export default {
  name: 'RequestErrorNotification',
  props: {
    error: String,
  },
  computed: {
    errorModel: {
      get() { return this.error; },
      set(value) { this.$emit('update:error', value); },
    },
  },
};
</script>
