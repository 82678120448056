<template>
  <section class="my-4">
    <page-top-area header-text="Overview">
      <template v-slot:button-area>
      </template>
    </page-top-area>
    <v-row>
      <v-col cols="12">
        <div class="text-subtitle-2 mt-5">
          Quick setup:
        </div>
        <div class="text-body-1 mt-2">
          1. Set
          <router-link to="/enabled-countries">
            <u>enabled countries</u>
          </router-link>
          <br />
          2. Configure <router-link to="/settings"  ><u>settings</u></router-link>
          <br />
          3. Add at least 3 <router-link to="/destinations"  ><u>destinations</u></router-link>
        </div>
        <div class="font-weight-light mt-7">
          Please notice that your website is not available to the public.
          <br />
          If you want change this or if you have questions, don't hesitate to contact us:
          <b> mail@yourtravelitinerary.com</b>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data() {
    return {
      features: [
      ],
    };
  },
  mounted() {
    if (this.$store.state.loggedIn === false) {
      this.$router.push('/login');
    }
  },
  components: {},
};
</script>

<style>

</style>
