<template>
  <page-top-area header-text="Edit homepage text" info-text="Set homepage text">
    <template v-slot:button-area>
    </template>
  </page-top-area>
  <EditTextContent slug="HOMEPAGE" :canAddItineraryCollection = true />
</template>

<script>
import EditTextContent from '@/components/EditTextContent.vue';

export default {
  components: { EditTextContent },
};
</script>
