<template>
  <v-row>
    <v-col cols="6">
      <v-text-field
        v-model="latModel"
        :error-messages="latErrors"
        label="Latitude"
        required
        type="Number"
        @input="v$.lat.$touch()"
        @blur="v$.lat.$touch()"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="lonModel"
        :error-messages="lonErrors"
        label="Longitude"
        required
        type="Number"
        @input="v$.lon.$touch()"
        @blur="v$.lon.$touch()"
      />
    </v-col>
  </v-row>
  <v-row>
<!--    <MarkerMap />-->
  </v-row>
</template>

<script>

// import MarkerMap from '@/components/MarkerMap.vue';

export default {
  name: 'GeoCoordinateInput',
  components: {
    // MarkerMap,
  },
  props: {
    selectedLat: Number,
    selectedLon: Number,
    latErrors: Object,
    lonErrors: Object,
    v$: Object,
  },
  methods: {
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    latModel: {
      get() {
        return this.selectedLat;
      },
      set(value) {
        this.v$.lat.$touch();
        this.$emit('update:selectedLat', value);
      },
    },
    lonModel: {
      get() {
        return this.selectedLon;
      },
      set(value) {
        this.v$.lon.$touch();
        this.$emit('update:selectedLon', value);
      },
    },
  },
};
</script>
