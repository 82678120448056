<template>
  <v-row justify="space-between">
    <v-col
      cols="12"
    >
      <v-container class="d-flex justify-center">
        <v-btn
          :disabled="v$.$error || !touchedOneCountry"
          :loading="loading"
          @click="handleSave"
          color="primary"
        >
          Save changes
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
  <v-row>
    <v-checkbox
      v-for="country in this.countries"
      :key="country.id"
      :label="country.name"
      :value="country.name"
      v-model="country.enabled"
      @click="handleTouchCountry(country)"
    ></v-checkbox>
  </v-row>
  <form>
    <v-row justify="space-between">
      <v-col
        cols="12"
      >
        <v-container class="d-flex justify-center">
          <v-btn
            :disabled="v$.$error || !touchedOneCountry"
            :loading="loading"
            @click="handleSave"
            color="primary"
          >
            Save changes
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
    <RequestErrorNotification :error="submitError" />
  </form>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';

export default {
  components: {
  },
  validations: {
  },
  mounted() {
    this.loadCountries();
  },
  data() {
    return ({
      v$: useVuelidate(),
      loading: false,
      submitError: null,
      countries: [],
      touchedCountries: {},
    });
  },
  computed: {
    touchedOneCountry() {
      return Object.keys(this.touchedCountries).length > 0;
    },
  },
  methods: {
    handleTouchCountry(country) {
      this.touchedCountries[country.id] = country;
    },
    handleSave() {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/enabled-countries`, this.buildRequestObject(), { withCredentials: true })
        .then(() => {
          this.submitError = 'Changes saved!';
          this.touchedCountries = {};
          this.$router.push('/enabled-countries');
        });
    },
    buildRequestObject() {
      return { countries: Object.keys(this.touchedCountries).map((key) => ({ countryId: this.touchedCountries[key].id, enabled: this.touchedCountries[key].enabled !== false })) };
    },
    loadCountries() {
      this.loading = true;
      axios.get(`${getBackendAdminHost(this.$store)}/admin/enabled-countries/list`, { withCredentials: true })
        .then((response) => {
          this.countries = response.data.map((country) => ({ ...country, enabled: country.enabled ? country.name : false }));
          this.loading = false;
        }).catch(() => {
          this.submitError = 'Error during request';
        });
    },
  },
};
</script>
