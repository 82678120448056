<template>
  <GeoEntityView :geo-base-type="this.geoBaseType" info-text-general="All infrastructures" />
</template>

<script>

import GeoEntityView from '@/views/GeoEntityView.vue';
import { geoBaseTypes } from '@/config/geoBaseTypes';

export default {
  components: {
    GeoEntityView,
  },
  data() {
    return ({
      geoBaseType: geoBaseTypes.INFRASTRUCTURE,
    });
  },

};
</script>
