<template>
  <LoaderPlaceholder
    v-if="this.loading"
  />
  <form v-else>
    <page-top-area
      :header-text='this.isNew() ? "New route" : "Edit " + this.name.substring(0, 50)'
      :info-text="'Manage a route'">
      <template v-slot:button-area>
        <v-row>
          <v-col cols="6">
          </v-col>
          <v-col cols="6">
            <v-btn
              prepend-icon="mdi-delete"
              variant="text"
              color="#4485ca"
              @click="handleClickDelete"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </page-top-area>
    <v-row v-if="this.parents?.length">
      <v-col cols="12">
        <span v-for="parent in this.parents" :key="parent.uuid">
          Parent route:
          <router-link :to="'/route/'+parent.uuid">
            {{ parent.name }}
          </router-link>
          <span v-if="this.parents.indexOf(parent) !== parents.length - 1">
            &gt;
          </span>
          </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <TransferTypeSelection
          v-if="this.isNew()"
          v-model:selectedValue="transferType"
          :v$="v$"
        />
        <v-text-field v-else v-model="transferTypeModel" label="Transfer type" readonly/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <GeoBaseSelection
          v-if="this.isNew()"
          v-model:selectedValue="startGeoBase"
          :v$="v$"
        />
        <v-text-field v-else v-model="startNameModel" label="Start" readonly/>
      </v-col>
      <v-col cols="6">
        <GeoBaseSelection
          v-if="this.isNew()"
          v-model:selectedValue="endGeoBase"
          :v$="v$"
        />
        <v-text-field v-else v-model="endNameModel" label="End" readonly/>
      </v-col>
    </v-row>
    <v-row v-if="this.existsGeoBasesOnPath()">
      <v-col
        cols="12"
      >
        <span class="text-h6">
          Subparts
        </span>
      </v-col>
      <v-col
        cols="12"
      >
        <v-table>
          <thead>
          <tr>
            <th class="text-left">
              Name
            </th>
            <th class="text-left">
              Distance on route (km)
            </th>
            <th class="text-left">
              Make a stop
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="entry in geoBasesOnPath"
            :key="entry.geoBase.uuid"
          >
            <td>{{ entry.geoBase.name }}</td>
            <td>{{ Math.ceil(entry.positionOnRouteDistance) }}</td>
            <td>
              <v-btn
                v-if="entry.existingRouteUuid != null"
                @click="handleShowPart(entry)"
                variant="text"
                color="#4485ca"
                class="pl-0"
              >
                Show part
              </v-btn>
              <v-btn
                v-if="entry.existingRouteUuid == null"
                @click="handleCreatePart(entry)"
                variant="text"
                color="#4485ca"
                class="pl-0"
              >
                Create part
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
    <v-row v-if="!this.loading && this.responseData !=null">
      <v-col>
        <RouteDataCard :routeData="this" :handle-refresh-data="loadBasicData" :uuid="this.uuid"
                       :onlyNameAndDescriptionEditable="this.children.length>0"/>
      </v-col>
    </v-row>
    <v-row v-if="this.isNew()">
      <v-col
        cols="6"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <v-btn
              @click="()=>handleRequestPreviewRoutes()"
              color="primary"
              :disabled="!this.canRequestRoutes()"
            >
              Request routes
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-select
              label="Possible routes"
              item-title="name"
              item-value="uuid"
              v-model="selectedPreviewRoute"
              :items="this.previewRoutes"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="6"
      >
        <v-file-input placeholder="Upload new image" v-model="fileUpload" truncate-length="15"/>
        <v-btn
          @click="()=>handleUploadRoute()"
          color="primary"
          :disabled="!this.canUploadRoutes()"
        >
          Upload route
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="space-between" v-if="selectedRoute !== null">
      <v-col
        cols="12"
      >
        <MapView :paths="this.getCurrentMapPaths()"/>
      </v-col>
    </v-row>
    <v-row v-if="selectedRoute !== null">
      <v-col
        cols="12"
        v-if="selectedRoute.children?.length > 0"
      >
        <v-divider/>
        <span class="text-h6">
          Subroutes
        </span>
      </v-col>
      <v-col
        v-for="route in selectedRoute.children"
        :key="route.uuid"
        cols="12"
      >
        <RouteDataCard :routeData="route" :handle-refresh-data="loadBasicData" :uuid="route.uuid"/>
      </v-col>
    </v-row>
    <v-row justify="space-between" v-if="this.isNew()">
      <v-col
        cols="6"
      >
        <v-btn
          @click="()=>handleSave()"
          :disabled="!this.canSave()"
          color="primary"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </form>
  <ConfirmationDialog
    :open="deleteModalOpen"
    :onAbort="handleAbortDeleteModal"
    :onConfirm="handleConfirmDeleteModal"
    :text="'Do you really want to delete?'"
  />
  <RequestErrorNotification :error="submitError"/>
</template>

<script>
import {
  maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getErrorMessages } from '@/utils/validationUtils';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import PageTopArea from '@/components/PageTopArea.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import GeoBaseSelection from '@/components/GeoBaseSelection.vue';
import QuillTextarea from '@/components/QuillTextarea.vue';
import TransferTypeSelection from '@/components/TransferTypeSelection.vue';
import { mapTransferTypeId } from '@/config/transferTypes';
import MapView from '@/components/MapView.vue';
import { getPathFromRoute } from '@/utils/geoUtils';
import RouteDataCard from '@/components/RouteDataCard.vue';

export default {
  components: {
    RouteDataCard,
    MapView,
    TransferTypeSelection,
    GeoBaseSelection,
    PageTopArea,
    ConfirmationDialog,
  },
  validations: {
    name: {
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    description: {
      maxLength: maxLength(50),
      minLength: minLength(0),
    },
    startGeoBase: {},
    endGeoBase: {},
    transferType: {},
    agencies: {},
  },
  mounted() {
    this.loadBasicData();
    this.touchIgnoreableElements();
  },
  data() {
    return ({
      loading: !this.isNew(),
      submitError: null,
      uuid: this.isNew() ? null : this.$route.params.uuid,
      v$: useVuelidate(),
      tab: null,
      deleteModalOpen: false,
      name: '',
      responseData: null,
      description: '',
      transferType: null,
      startGeoBase: null,
      endGeoBase: null,
      path: null,
      previewRoutes: [],
      selectedRoute: null,
      selectedPreviewRoute: null,
      costsMin: null,
      costsMax: null,
      costs: null,
      durationMin: null,
      durationMax: null,
      duration: null,
      transferTypeModel: null,
      startNameModel: null,
      endNameModel: null,
      agencies: [],
      agenciesNames: [],
      parents: [],
      fileUpload: '',
      uploadPath: `${getBackendAdminHost(this.$store)}/admin/route/upload`,
      geoBasesOnPath: [],
    });
  },
  computed: {
    nameErrors() {
      return getErrorMessages(this.v$.name, 'Name');
    },
    descriptionErrors() {
      return getErrorMessages(this.v$.description, 'Description');
    },
  },
  watch: {
    selectedPreviewRoute() {
      if (this.isNew()) {
        this.selectedRoute = this.previewRoutes.find((route) => route.uuid === this.selectedPreviewRoute);
      }
    },
    previewRoutes() {
      if (this.previewRoutes.length > 0) {
        this.selectedPreviewRoute = this.previewRoutes[0].uuid;
      }
    },
  },
  methods: {
    isNew() {
      return this.$route.params.uuid === 'new';
    },
    loadBasicData() {
      if (!this.uuid) {
        return;
      }
      this.loading = false;
      axios.get(`${getBackendAdminHost(this.$store)}/admin/route/${this.uuid}`, { withCredentials: true })
        .then((response) => {
          this.applyResponseData(response.data);
          this.selectedRoute = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.submitError = 'Error during request';
        });

      axios.get(`${getBackendAdminHost(this.$store)}/admin/route/${this.uuid}/detect-geo-bases-on-path`, { withCredentials: true })
        .then((response) => {
          this.geoBasesOnPath = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.submitError = 'Error during request';
        });
    },
    handleUploadRoute() {
      this.loading = true;
      const formData = new FormData();
      formData.append('file', this.fileUpload[0]);
      if (this.isNew()) {
        this.uploadPath = `${getBackendAdminHost(this.$store)}/admin/route/new-upload/${this.startGeoBase}/${this.endGeoBase}/${this.transferType}`;
      } else {
        this.uploadPath = `${getBackendAdminHost(this.$store)}/admin/route/${this.uuid}/upload`;
      }

      axios.post(this.uploadPath, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          this.previewRoutes = [response.data];
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
          this.fileUpload = '';
        });
    },
    handleShowPart(entry) {
      window.location.href = `/route/${entry.existingRouteUuid}`;
    },
    handleCreatePart(entry) {
      this.loading = true;
      axios.post(`${getBackendAdminHost(this.$store)}/admin/route/${this.uuid}/create-part`, { geoBaseUuid: entry.geoBase.uuid }, { withCredentials: true })
        .then(() => {
          window.location.href = `/route/${this.uuid}`;
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCurrentMapPaths() {
      if (this.isNew() && !this.selectedPreviewRoute) {
        return [];
      }
      if (this.selectedRoute.path) {
        return [this.selectedRoute.path];
      }
      return this.selectedRoute.children.map((child) => getPathFromRoute(child));
    },
    existsGeoBasesOnPath() {
      return this.geoBasesOnPath?.length > 1;
    },
    applyResponseData(data) {
      this.responseData = data;
      this.name = data.name;
      this.description = data.description;
      this.transferType = data.transferTypeId;
      this.transferTypeModel = mapTransferTypeId(data.transferTypeId).name;
      this.startGeoBase = data.startGeoBase.uuid;
      this.endGeoBase = data.endGeoBase.uuid;
      this.startNameModel = data.startGeoBase.name;
      this.endNameModel = data.endGeoBase.name;
      this.path = data.path;
      this.duration = data.duration;
      this.durationMin = data.durationMin;
      this.durationMax = data.durationMax;
      this.costs = data.costs;
      this.costsMin = data.costsMin;
      this.costsMax = data.costsMax;
      this.children = data.children || null;
      this.agencies = data.agencies || [];
      this.parents = data.parents || [];
      this.uploadPath = `${getBackendAdminHost(this.$store)}/admin/route/${this.uuid}/upload`;
    },
    handleClickDelete() {
      this.deleteModalOpen = true;
    },
    handleAbortDeleteModal() {
      this.deleteModalOpen = false;
    },
    buildRequestPreviewObject() {
      return {
        transferTypeId: this.transferType,
        startGeoBaseUuid: this.startGeoBase,
        endGeoBaseUuid: this.endGeoBase,
      };
    },
    update(requestBody) {
      axios.put(`${getBackendAdminHost(this.$store)}/admin/route/${this.uuid}`, requestBody, { withCredentials: true })
        .then(() => {
          this.$router.push('/route');
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    assignPreviewRoute() {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/route/${this.selectedRoute.uuid}/assign`, {}, { withCredentials: true })
        .then((response) => {
          this.uuid = response.data.uuid;
          window.location.href = `/route/${response.data.uuid}`;
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleConfirmDeleteModal() {
      axios.delete(`${getBackendAdminHost(this.$store)}/admin/route/${this.uuid}`, { withCredentials: true })
        .then(() => {
          this.$router.push('/route');
        })
        .catch(() => {
          this.loading = false;
          this.submitError = 'Error during request';
        });
    },
    canRequestRoutes() {
      return this.transferType !== null && this.startGeoBase && this.endGeoBase;
    },
    canUploadRoutes() {
      return this.canRequestRoutes() && this.fileUpload !== '';
    },
    canSave() {
      if (this.isNew()) {
        return this.transferType !== null && this.startGeoBase && this.endGeoBase && this.selectedPreviewRoute;
      }
      return this.name;
    },
    handleSave() {
      this.loading = true;

      this.assignPreviewRoute();
    },
    handleRequestPreviewRoutes() {
      this.loading = true;

      const requestBody = this.buildRequestPreviewObject();

      axios.post(`${getBackendAdminHost(this.$store)}/admin/route/request-preview`, requestBody, { withCredentials: true })
        .then((response) => {
          this.previewRoutes = response.data;
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    touchIgnoreableElements() {
      this.v$.description.$touch();
      this.v$.startGeoBase.$touch();
      this.v$.endGeoBase.$touch();
    },
  },

};
</script>
