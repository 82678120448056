export const geoBaseTypes = {
  ACCOMMODATION: {
    id: 0,
    path: 'accommodation',
    name: 'Accommodation',
  },
  AREA: {
    id: 1,
    path: 'area',
    name: 'Area',
  },
  ATTRACTION: {
    id: 2,
    path: 'attraction',
    name: 'Attraction',
  },
  EXPERIENCE: {
    id: 3,
    path: 'experience',
    name: 'Experience',
  },
  INFRASTRUCTURE: {
    id: 4,
    path: 'infrastructure',
    name: 'Infrastructure',
  },
  DESTINATION: {
    id: 5,
    path: 'destination',
    name: 'Destination',
  },
  WAY: {
    id: 6,
    path: 'way',
    name: 'Way',
  },
};

export const geoBaseTypesAsList = () => Object.values(geoBaseTypes);

export const mapGeoBaseTypeId = (id) => {
  const result = Object.values(geoBaseTypes)
    .find((geoBaseType) => geoBaseType.id === id);
  if (result) {
    return result;
  }
  console.error('invalid geo type id: ', id);
  return null;
};

export const mapGeoBaseName = (geoBaseName) => {
  const result = Object.values(geoBaseTypes)
    .find((geoBaseType) => geoBaseType.name.toLowerCase() === geoBaseName.toLowerCase());
  if (result) {
    return result;
  }
  console.error('invalid geo type id: ', id);
  return null;
};
