<template>
  <form>
    <page-top-area :header-text='this.isNew() ? "New article" : "Edit: "+this.title' info-text="Edit article">
    </page-top-area>
    <v-row>
      <v-col>
        <v-text-field
          v-model="title"
          :error-messages="titleErrors"
          label="Title"
          required
          @input="v$.title.$touch()"
          @blur="v$.title.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <QuillTextarea v-if="!this.loading" v-model:content="content" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          label="Linked itineraries collections"
          v-model="selectedItinerariesCollections"
          :items="this.itinerariesCollections"
          :item-value="item => item.uuid"
          :item-title="item => item.name"
          multiple
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="order"
          :error-messages="orderErrors"
          label="Order"
          required
          @input="v$.order.$touch()"
          @blur="v$.order.$touch()"
        />
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col
        cols="12"
      >
        <v-btn
          class="float-left"
          :loading="loading"
          @click="handleDelete"
        >
          Delete
        </v-btn>
        <v-btn
          class="float-right"
          :disabled="(!v$.$dirty && this.isNew()) || v$.$error"
          :loading="loading"
          @click="handleSave"
        >
          Save Article
        </v-btn>
      </v-col>
    </v-row>
    <RequestErrorNotification :error="submitError" />
  </form>
</template>

<script>
import {
  required, maxLength, minLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getErrorMessages } from '@/utils/validationUtils';
import axios from 'axios';
import { getBackendAdminHost } from '@/utils/urlUtils';
import QuillTextarea from '@/components/QuillTextarea.vue';

export default {
  components: {
    QuillTextarea,
  },
  validations: {
    content: {
      required,
      maxLength: maxLength(10000),
      minLength: minLength(3),
    },
    title: {
      required,
      maxLength: maxLength(250),
      minLength: minLength(3),
    },
    order: {
      required,
      maxLength: maxLength(5),
      minLength: minLength(0),
    },
  },
  mounted() {
    this.loadData();
    this.loadItineraryCollections();
    this.touchIgnoreableElements();
  },
  data() {
    return ({
      v$: useVuelidate(),
      articleId: this.isNew() ? null : this.$route.params.articleId,
      loading: !this.isNew(),
      submitError: null,
      content: '',
      title: '',
      order: 0,
      updated: 0,
      itinerariesCollections: [],
      selectedItinerariesCollections: [],
    });
  },
  computed: {
    titleErrors() {
      return getErrorMessages(this.v$.title, 'Title');
    },
    contentErrors() {
      return getErrorMessages(this.v$.content, 'Content');
    },
    orderErrors() {
      return getErrorMessages(this.v$.order, 'Order');
    },
  },
  methods: {
    isNew() {
      return this.$route.params.articleId === 'new';
    },
    loadData() {
      // eslint-disable-next-line no-restricted-globals
      if (!this.articleId) {
        return;
      }
      axios.get(`${getBackendAdminHost(this.$store)}/admin/article/${this.articleId}`, { withCredentials: true })
        .then((response) => {
          this.applyResponseData(response.data);
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
        });
    },
    applyResponseData(data) {
      this.content = data.content;
      this.title = data.title;
      this.order = data.order;
      this.selectedItinerariesCollections = data.linkedItinerariesCollectionsIds || [];
    },
    loadItineraryCollections() {
      axios.get(`${getBackendAdminHost(this.$store)}/admin/itineraries-collection/public`, { withCredentials: true })
        .then((response) => {
          this.itinerariesCollections = response.data;
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
        });
    },
    create(requestBody) {
      axios.post(`${getBackendAdminHost(this.$store)}/admin/article`, requestBody, { withCredentials: true })
        .then((response) => {
          this.$router.push(`/article/${response.data.id}`);
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
        });
    },
    update(requestBody) {
      axios.put(`${getBackendAdminHost(this.$store)}/admin/article/${this.articleId}`, requestBody, { withCredentials: true })
        .then(() => {
          this.$router.push('/articles');
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
        });
    },
    handleDelete() {
      axios.delete(`${getBackendAdminHost(this.$store)}/admin/article/${this.articleId}`, { withCredentials: true })
        .then(() => {
          this.$router.push('/articles');
        }).catch(() => {
          this.submitError = 'Error during request';
        }).finally(() => {
          this.loading = false;
        });
    },
    handleSave() {
      this.loading = true;
      this.submitError = null;

      const requestBody = {
        content: this.content,
        title: this.title,
        order: this.order,
        linkedItinerariesCollectionsIds: this.selectedItinerariesCollections,
      };

      if (this.isNew()) {
        this.create(requestBody);
      } else {
        this.update(requestBody);
      }
    },
    touchIgnoreableElements() {
      this.v$.content.$touch();
    },
  },
};
</script>
