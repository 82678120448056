<template>
  <LoaderPlaceholder
    v-if="this.loading"
  />
  <form v-else>
    <v-row>
      <v-col>
        <h1 class="text-h5">
          Create new itinerary
        </h1>
      </v-col>
    </v-row>
    <v-row v-if="isNew()">
      <v-col>
        <v-select
          label="Start stop"
          v-model="selectedStartGeobase"
          item-title="text"
          item-value="value"
          :items="this.geoBaseList"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="8"
      >
        <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          label="Itinerary name"
          required
          @input="v$.name.$touch()"
          @blur="v$.name.$touch()"
        />
      </v-col>
      <v-col
        cols="4"
      >
        <v-select
          v-if="!isNew()"
          label="Visbilility"
          v-model="selectedPublicMode"
          item-title="text"
          item-value="value"
          :items="this.publicModeList"
        ></v-select>
      </v-col>
    </v-row>
    <v-row  v-if="isNew()">
      <v-col
        cols="6"
      >
        <v-btn
          class="float-right d-inline"
          :disabled="!v$.$dirty || v$.$error"
          :loading="loading"
          @click="handleCreate"
          color="primary"
        >
          Create
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!isNew()">
      <v-col cols="4">
        <v-btn
          class="float-left d-inline"
          :loading="loading"
          @click="handleDelete"
          color="primary"
        >
          Delete itinerary
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn
          class="float-left d-inline"
          :disabled="!v$.$dirty || v$.$error"
          :loading="loading"
          @click="handleUpdate"
          color="primary"
        >
          Update
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn
          class="d-inline"
          :loading="loading"
          @click="handleOpen"
          color="primary"
        >
          Open to edit
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<script>

import { geoBaseTypes } from '@/config/geoBaseTypes';
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, required } from '@vuelidate/validators';
import axios from 'axios';
import { getBackendAdminHost, getCurrentSubdomain } from '@/utils/urlUtils';
import { getErrorMessages } from '@/utils/validationUtils';

export default {
  components: {},
  validations: {
    name: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
  },
  computed: {
    nameErrors() {
      return getErrorMessages(this.v$.name, 'Name');
    },
  },
  mounted() {
    this.loadItinerary();
  },
  data() {
    return ({
      v$: useVuelidate(),
      geoBaseType: geoBaseTypes.INFRASTRUCTURE,
      loading: false,
      geoBaseList: [],
      selectedStartGeobase: null,
      selectedPublicMode: null,
      publicModeList: [
        {
          text: 'Private',
          value: 0,
        },
        {
          text: 'Public',
          value: 1,
        }],
      name: '',
      hash: '',
    });
  },
  methods: {
    isNew() {
      return this.$route.params.id === 'new';
    },
    handleCreate() {
      axios.post(
        `${getBackendAdminHost(this.$store)}/admin/itinerary/create`,
        this.buildCreateRequest(),
        { withCredentials: true },
      )
        .then(() => {
          this.$router.push('/itineraries');
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleUpdate() {
      axios.put(
        `${getBackendAdminHost(this.$store)}/admin/itinerary/${this.$route.params.id}`,
        this.buildCreateRequest(),
        { withCredentials: true },
      )
        .then(() => {
          this.loadItinerary();
          this.submitError = 'Updated successfully';
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleOpen() {
      window.open(`${getCurrentSubdomain(this.$store.state)}/itinerary/${this.$route.params.id}?itineraryHash=${this.hash}`, '_blank');
    },
    handleDelete() {
      axios.delete(
        `${getBackendAdminHost(this.$store)}/admin/itinerary/${this.$route.params.id}`,
        { withCredentials: true },
      )
        .then(() => {
          this.$router.push('/itineraries');
        })
        .catch(() => {
          this.submitError = 'Error during request';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildCreateRequest() {
      return {
        name: this.name,
        selectedStartGeobaseUuid: this.selectedStartGeobase,
        publicMode: this.selectedPublicMode,
      };
    },
    applyLoadedItinerary(itineraryResponse) {
      this.name = itineraryResponse.name;
      this.hash = itineraryResponse.hash;
      this.selectedPublicMode = itineraryResponse.publicMode;
      this.v$.name.$touch();
    },
    loadItinerary() {
      this.loading = true;
      if (this.isNew()) {
        axios.get(`${getBackendAdminHost(this.$store)}/admin/geo-base/possible-starters`, { withCredentials: true })
          .then((response) => {
            this.geoBaseList = [...response.data.map((r) => ({
              text: r.name,
              value: r.uuid,
            }))];
            this.loading = false;
          });
      } else {
        axios.get(`${getBackendAdminHost(this.$store)}/admin/itinerary/${this.$route.params.id}`, { withCredentials: true })
          .then((response) => {
            this.applyLoadedItinerary(response.data);
            this.loading = false;
          });
      }
    },
  },

};
</script>
