export const settingTypes = {
  TITLE: 'TITLE',
  MAIN_COLOR: 'MAIN_COLOR',
  HEADER_TEXT_COLOR: 'HEADER_TEXT_COLOR',
  HEADER_COLOR: 'HEADER_COLOR',
  HEADER_SHADOW: 'HEADER_SHADOW',
  TEASER_TITLE: 'TEASER_TITLE',
  TEASER_SUBTITLE: 'TEASER_SUBTITLE',
  TEASER_IMAGE: 'TEASER_IMAGE',
  LOGO: 'LOGO',
};
